export default {
  'common.account_number': 'Kontonummer',
  'common.address': 'Adresse',
  'common.amount': 'Beløb',
  'common.approved': 'Godkendt',
  'common.are_you_sure': 'Er du sikker?',
  'common.bank_information': 'Bankoplysninger',
  'common.birth_date': 'Fødselsdato',
  'common.city': 'By',
  'common.close': 'Luk',
  'common.code': 'Kode',
  'common.cost_center': 'Omkostningssted',
  'common.country': 'Land',
  'common.country_code': 'Landekode',
  'common.currency': 'Valuta',
  'common.date': 'Dato',
  'common.day': 'Dag',
  'common.days': 'Dage',
  'common.department': 'Afdeling',
  'common.description': 'Beskrivelse',
  'common.duration': 'Varighed',
  'common.email': 'E-mail',
  'common.end': 'Slut',
  'common.friday': 'fredag',
  'common.from': 'Fra',
  'common.gender': 'Køn',
  'common.go_back': 'Gå tilbage',
  'common.log_in': 'Log ind',
  'common.log_out': 'Log ud',
  'common.monday': 'mandag',
  'common.month': 'Måned',
  'common.name': 'Navn',
  'common.no': 'Nej',
  'common.no_cost_center': 'Intet omkostningssted',
  'common.no_department': 'Egen afdeling',
  'common.none': 'Ingen',
  'common.not_approved': 'Ikke godkendt',
  'common.note': 'Note',
  'common.one_time_code': 'Engangskode',
  'common.password': 'Adgangskode',
  'common.phone_number': 'Telefonnummer',
  'common.post_code': 'Postnr.',
  'common.reg_number': 'Reg.nr.',
  'common.saturday': 'lørdag',
  'common.save': 'Gem',
  'common.save_changes': 'Gem ændringer',
  'common.select_country': 'Vælg land',
  'common.select_gender': 'Vælg køn',
  'common.show_history': 'Vis historik',
  'common.start': 'Start',
  'common.sunday': 'søndag',
  'common.thursday': 'torsdag',
  'common.to': 'Til',
  'common.tuesday': 'tirsdag',
  'common.type': 'Type',
  'common.unknown': 'Ukendt',
  'common.wednesday': 'onsdag',
  'common.year': 'År',
  'common.yes': 'Ja',

  'date.date_short_format': 'do MMM yyyy',
  'date.day_month': 'do MMMM',
  'date.day_month_short': 'do MMM',
  'date.day_of_month': 'do',

  'unit.days.many': 'dage',
  'unit.days.one': 'dag',
  'unit.hours.many': 'timer',
  'unit.hours.one': 'time',
  'unit.km': 'km',
  'unit.minutes.many': 'minutter',
  'unit.minutes.one': 'minut',

  'app.version_deprecated': 'Din nuværende app-version er forældret. Opdater venligst til nyeste version.',

  'car_allowance.also_create_return_trip': 'Opret også kørsel retur',
  'car_allowance.avoid_ferries': 'Undgå færger',
  'car_allowance.distance': 'Afstand',
  'car_allowance.enter_to_from': 'indtast til/fra',
  'car_allowance.license_plate': 'Nummerplade',
  'car_allowance.no_car_allowances_yet': 'Du har ikke registreret kørsel endnu.',
  'car_allowance.no_historic_car_allowances': 'Du har ingen historiske registreringer.',
  'car_allowance.no_return_route_found': 'Der kunne ikke findes en retur-rute mellem de to punkter.',
  'car_allowance.no_route_found': 'Der kunne ikke findes en rute mellem de to punkter.',
  'car_allowance.purpose': 'Formål',
  'car_allowance.rate': 'Sats',
  'car_allowance.rate.above_limit': 'Sats må ikke være mere end {limit}',
  'car_allowance.rate.invalid': 'Sats er ugyldig',
  'car_allowance.rate.suffix': 'kr.',
  'car_allowance.register_car_allowance': 'Registrer kørsel',
  'car_allowance.suggested_route': 'Foreslået rute',
  'car_allowance.table.rate_format': 'Sats: {rate}',

  'country_codes.ad': 'Andorra',
  'country_codes.ae': 'Forenede Arabiske Emirater',
  'country_codes.af': 'Afghanistan',
  'country_codes.ag': 'Antigua og Barbuda',
  'country_codes.ai': 'Anguilla',
  'country_codes.al': 'Albanien',
  'country_codes.am': 'Armenien',
  'country_codes.ao': 'Angola',
  'country_codes.aq': 'Antarktika',
  'country_codes.ar': 'Argentina',
  'country_codes.as': 'Amerikansk Samoa',
  'country_codes.at': 'Østrig',
  'country_codes.au': 'Australien',
  'country_codes.aw': 'Aruba',
  'country_codes.ax': 'Åland',
  'country_codes.az': 'Azerbaijan',
  'country_codes.ba': 'Bosnien-Hercegovina',
  'country_codes.bb': 'Barbados',
  'country_codes.bd': 'Bangladesh',
  'country_codes.be': 'Belgien',
  'country_codes.bf': 'Burkina Faso',
  'country_codes.bg': 'Bulgarien',
  'country_codes.bh': 'Bahrain',
  'country_codes.bi': 'Burundi',
  'country_codes.bj': 'Benin',
  'country_codes.bl': 'Saint Barthélemy',
  'country_codes.bm': 'Bermuda',
  'country_codes.bn': 'Brunei',
  'country_codes.bo': 'Bolivia',
  'country_codes.bq': 'Bonaire, Sankt Eustatius og Saba',
  'country_codes.br': 'Brasilien',
  'country_codes.bs': 'Bahamas',
  'country_codes.bt': 'Bhutan',
  'country_codes.bv': 'Bouvetøen',
  'country_codes.bw': 'Botswana',
  'country_codes.by': 'Hviderusland',
  'country_codes.bz': 'Belize',
  'country_codes.ca': 'Canada',
  'country_codes.cc': 'Cocosøerne (eller Keelingøerne)',
  'country_codes.cd': 'Demokratiske republik Congo',
  'country_codes.cf': 'Centralafrikanske republik',
  'country_codes.cg': 'Congo',
  'country_codes.ch': 'Schweiz',
  'country_codes.ci': 'Elfenbenskysten',
  'country_codes.ck': 'Cookøerne',
  'country_codes.cl': 'Chile',
  'country_codes.cm': 'Cameroon',
  'country_codes.cn': 'Kina',
  'country_codes.co': 'Colombia',
  'country_codes.cr': 'Costa Rica',
  'country_codes.cu': 'Cuba',
  'country_codes.cv': 'Cabo Verde',
  'country_codes.cw': 'Curaçao',
  'country_codes.cx': 'Juleøen',
  'country_codes.cy': 'Kypern',
  'country_codes.cz': 'Tjekkiet',
  'country_codes.de': 'Tyskland',
  'country_codes.dj': 'Djibouti',
  'country_codes.dk': 'Danmark',
  'country_codes.dm': 'Dominica',
  'country_codes.do': 'Dominikanske Republik',
  'country_codes.dz': 'Algeriet',
  'country_codes.ec': 'Ecuador',
  'country_codes.ee': 'Estland',
  'country_codes.eg': 'Ægypten',
  'country_codes.eh': 'Vestsahara',
  'country_codes.er': 'Eritrea',
  'country_codes.es': 'Spanien',
  'country_codes.et': 'Ethiopia',
  'country_codes.fi': 'Finland',
  'country_codes.fj': 'Fiji',
  'country_codes.fk': 'Falklandsøerne',
  'country_codes.fm': 'Mikronesien',
  'country_codes.fo': 'Færøerne',
  'country_codes.fr': 'Frankrig',
  'country_codes.ga': 'Gabon',
  'country_codes.gb': 'Forenede Kongerige (UK)',
  'country_codes.gd': 'Grenada',
  'country_codes.ge': 'Georgien',
  'country_codes.gf': 'Fransk Guiana',
  'country_codes.gg': 'Guernsey',
  'country_codes.gh': 'Ghana',
  'country_codes.gi': 'Gibraltar',
  'country_codes.gl': 'Grønland',
  'country_codes.gm': 'Gambia',
  'country_codes.gn': 'Guinea',
  'country_codes.gp': 'Guadeloupe',
  'country_codes.gq': 'Ækvatorialguinea',
  'country_codes.gr': 'Grækenland',
  'country_codes.gs': 'South Georgia og de Sydlige Sandwichøer',
  'country_codes.gt': 'Guatemala',
  'country_codes.gu': 'Guam',
  'country_codes.gw': 'Guinea-Bissau',
  'country_codes.gy': 'Guyana',
  'country_codes.hk': 'Hongkong',
  'country_codes.hm': 'Heardøen og McDonaldøerne',
  'country_codes.hn': 'Honduras',
  'country_codes.hr': 'Kroatien',
  'country_codes.ht': 'Haiti',
  'country_codes.hu': 'Ungarn',
  'country_codes.id': 'Indonesien',
  'country_codes.ie': 'Irland',
  'country_codes.il': 'Israel',
  'country_codes.im': 'Isle of Man',
  'country_codes.in': 'Indien',
  'country_codes.io': 'Britisk territorium i det indiske ocean',
  'country_codes.iq': 'Irak',
  'country_codes.ir': 'Iran',
  'country_codes.is': 'Island',
  'country_codes.it': 'Italien',
  'country_codes.je': 'Jersey',
  'country_codes.jm': 'Jamaica',
  'country_codes.jo': 'Jordan',
  'country_codes.jp': 'Japan',
  'country_codes.ke': 'Kenya',
  'country_codes.kg': 'Kyrgyzstan',
  'country_codes.kh': 'Cambodia',
  'country_codes.ki': 'Kiribati',
  'country_codes.km': 'Comoros',
  'country_codes.kn': 'Saint Kitts og Nevis',
  'country_codes.kp': 'Nordkorea',
  'country_codes.kr': 'Sydkorea',
  'country_codes.kw': 'Kuwait',
  'country_codes.ky': 'Caymanøerne',
  'country_codes.kz': 'Kasakhstan',
  'country_codes.la': 'Laos',
  'country_codes.lb': 'Lebanon',
  'country_codes.lc': 'Saint Lucia',
  'country_codes.li': 'Liechtenstein',
  'country_codes.lk': 'Sri Lanka',
  'country_codes.lr': 'Liberia',
  'country_codes.ls': 'Lesotho',
  'country_codes.lt': 'Lithauen',
  'country_codes.lu': 'Luxembourg',
  'country_codes.lv': 'Letland',
  'country_codes.ly': 'Libyen',
  'country_codes.ma': 'Morocco',
  'country_codes.mc': 'Monaco',
  'country_codes.md': 'Moldova',
  'country_codes.me': 'Montenegro',
  'country_codes.mf': 'Saint Martin (Fransk del)',
  'country_codes.mg': 'Madagascar',
  'country_codes.mh': 'Marshall-øerne',
  'country_codes.mk': 'Makedonien',
  'country_codes.ml': 'Mali',
  'country_codes.mm': 'Myanmar',
  'country_codes.mn': 'Mongoliet',
  'country_codes.mo': 'Macao',
  'country_codes.mp': 'Nordmarianerne',
  'country_codes.mq': 'Martinique',
  'country_codes.mr': 'Mauretanien',
  'country_codes.ms': 'Montserrat',
  'country_codes.mt': 'Malta',
  'country_codes.mu': 'Mauritius',
  'country_codes.mv': 'Maldiverne',
  'country_codes.mw': 'Malawi',
  'country_codes.mx': 'Mexico',
  'country_codes.my': 'Malaysia',
  'country_codes.mz': 'Mozambique',
  'country_codes.na': 'Namibia',
  'country_codes.nc': 'Ny Caledonien',
  'country_codes.ne': 'Niger',
  'country_codes.nf': 'Norfolk Island',
  'country_codes.ng': 'Nigeria',
  'country_codes.ni': 'Nicaragua',
  'country_codes.nl': 'Nederlandene',
  'country_codes.no': 'Norge',
  'country_codes.np': 'Nepal',
  'country_codes.nr': 'Nauru',
  'country_codes.nu': 'Niue',
  'country_codes.nz': 'New Zealand',
  'country_codes.om': 'Oman',
  'country_codes.pa': 'Panama',
  'country_codes.pe': 'Peru',
  'country_codes.pf': 'Fransk Polynesia',
  'country_codes.pg': 'Papua Ny Guinea',
  'country_codes.ph': 'Filippinerne',
  'country_codes.pk': 'Pakistan',
  'country_codes.pl': 'Polen',
  'country_codes.pm': 'St. Pierre og Miquelon',
  'country_codes.pn': 'Pitcairn',
  'country_codes.pr': 'Puerto Rico',
  'country_codes.ps': 'Palæstina',
  'country_codes.pt': 'Portugal',
  'country_codes.pw': 'Palau',
  'country_codes.py': 'Paraguay',
  'country_codes.qa': 'Qatar',
  'country_codes.re': 'Réunion',
  'country_codes.ro': 'Rumænien',
  'country_codes.rs': 'Serbien',
  'country_codes.ru': 'Rusland',
  'country_codes.rw': 'Rwanda',
  'country_codes.sa': 'Saudi-Arabien',
  'country_codes.sb': 'Solomon Islands',
  'country_codes.sc': 'Seychelles',
  'country_codes.sd': 'Sudan',
  'country_codes.se': 'Sverige',
  'country_codes.sg': 'Singapore',
  'country_codes.sh': 'Saint Helena, Ascension og Tristan da Cunha',
  'country_codes.si': 'Slovenien',
  'country_codes.sj': 'Svalbard og Jan Mayen',
  'country_codes.sk': 'Slovakiet',
  'country_codes.sl': 'Sierra Leone',
  'country_codes.sm': 'San Marino',
  'country_codes.sn': 'Senegal',
  'country_codes.so': 'Somalia',
  'country_codes.sr': 'Suriname',
  'country_codes.ss': 'Sydsudan',
  'country_codes.st': 'Sao Tome og Principe',
  'country_codes.sv': 'El Salvador',
  'country_codes.sx': 'Sint Maarten (Nederlandsk del)',
  'country_codes.sy': 'Syrien',
  'country_codes.sz': 'Eswatini',
  'country_codes.tc': 'Turks- og Caicosøerne',
  'country_codes.td': 'Tchad',
  'country_codes.tf': 'Franske besiddelser i det sydlige indiske ocean',
  'country_codes.tg': 'Togo',
  'country_codes.th': 'Thailand',
  'country_codes.tj': 'Tajikistan',
  'country_codes.tk': 'Tokelau',
  'country_codes.tl': 'Timor-Leste',
  'country_codes.tm': 'Turkmenistan',
  'country_codes.tn': 'Tunisien',
  'country_codes.to': 'Tonga',
  'country_codes.tr': 'Tyrkiet',
  'country_codes.tt': 'Trinidad og Tobago',
  'country_codes.tv': 'Tuvalu',
  'country_codes.tw': 'Taiwan',
  'country_codes.tz': 'Tanzania',
  'country_codes.ua': 'Ukraine',
  'country_codes.ug': 'Uganda',
  'country_codes.um': 'USAs mindre øbesiddelser',
  'country_codes.us': 'Amerikas Forenede Stater (USA)',
  'country_codes.uy': 'Uruguay',
  'country_codes.uz': 'Uzbekistan',
  'country_codes.va': 'Vatikanstaten',
  'country_codes.vc': 'St. Vincent og Grenadinerne',
  'country_codes.ve': 'Venezuela',
  'country_codes.vg': 'Jomfruøerne (Britisk)',
  'country_codes.vi': 'Jomfruøerne (Amerikansk)',
  'country_codes.vn': 'Vietnam',
  'country_codes.vu': 'Vanuatu',
  'country_codes.wf': 'Wallis og Futuna',
  'country_codes.ws': 'Samoa',
  'country_codes.xi': 'Nordirland',
  'country_codes.xu': 'Storbritannien (det Forenede Kongerige (UK) uden Nordirland)',
  'country_codes.ye': 'Yemen',
  'country_codes.yt': 'Mayotte',
  'country_codes.za': 'Sydafrika',
  'country_codes.zm': 'Zambia',
  'country_codes.zw': 'Zimbabwe',

  'dashboard.card_car_allowance.alert.success': 'Kørsel gemt',
  'dashboard.card_car_allowance.go_to_page': 'Alle kørselsregistreringer',
  'dashboard.card_car_allowance.register_new': 'Ny kørsel',
  'dashboard.card_car_allowance.summary.count.many': '{number} kørsler',
  'dashboard.card_car_allowance.summary.count.one': '{number} kørsel',
  'dashboard.card_car_allowance.summary.km': '{number} km',
  'dashboard.card_car_allowance.summary.line_1': 'Kørsel i dag, {day}:',
  'dashboard.card_car_allowance.summary.line_2': '{kilometres} fordelt på {number}.',
  'dashboard.card_car_allowance.title': 'Kørsel',
  'dashboard.card_default.gross_pay': 'Bruttoløn',
  'dashboard.card_default.hide_pay': 'Skjul løn',
  'dashboard.card_default.latest_paycheck_from': 'Seneste lønudbetaling fra',
  'dashboard.card_default.net_pay': 'Nettoløn',
  'dashboard.card_default.next_paycheck_from': 'Næste lønudbetaling fra',
  'dashboard.card_default.show_pay': 'Vis løn',
  'dashboard.card_flex_time_registrations.alert.success': 'Flekstid gemt',
  'dashboard.card_flex_time_registrations.go_to_page': 'Alle flekstidsregistreringer',
  'dashboard.card_flex_time_registrations.register_new': 'Ny registrering',
  'dashboard.card_flex_time_registrations.title': 'Flekstid',
  'dashboard.card_leave_registration.alert.success': 'Fravær gemt',
  'dashboard.card_leave_registration.go_to_page': 'Alle fraværsregistreringer',
  'dashboard.card_leave_registration.register_new': 'Ny fravær',
  'dashboard.card_leave_registration.title': 'Fravær for {date}',
  'dashboard.card_overtime_registrations.alert.success': 'Afspadsering gemt',
  'dashboard.card_overtime_registrations.go_to_page': 'Alle afspadseringsregistreringer',
  'dashboard.card_overtime_registrations.register_new': 'Ny registrering',
  'dashboard.card_overtime_registrations.title': 'Afspadsering',
  'dashboard.card_project_registrations.alert.success': 'Projekttid gemt',
  'dashboard.card_project_registrations.go_to_page': 'Alle projekttidsregistreringer',
  'dashboard.card_project_registrations.register_new': 'Ny registrering',
  'dashboard.card_project_registrations.title': 'Projekttid',
  'dashboard.card_salary_registration.alert.success': 'Løntillæg gemt',
  'dashboard.card_salary_registration.go_to_page': 'Alle løntillægsregistreringer',
  'dashboard.card_salary_registration.register_new': 'Nyt løntillæg',
  'dashboard.card_salary_registration.summary.line': '{quantity} × {rate} = {total}',
  'dashboard.card_salary_registration.summary.total': 'Total: {total}',
  'dashboard.card_salary_registration.title': 'Løntillæg',
  'dashboard.card_time_registrations.alert.success': 'Tid gemt',
  'dashboard.card_time_registrations.go_to_page': 'Alle tidsregistreringer',
  'dashboard.card_time_registrations.register_new': 'Ny registrering',
  'dashboard.card_time_registrations.title': 'Tidsregistrering',
  'dashboard.card_work_hours.alert.success': 'Arbejdstid gemt',
  'dashboard.card_work_hours.form.break_minutes': 'Pause',
  'dashboard.card_work_hours.form.break_minutes.suffix': 'minutter',
  'dashboard.card_work_hours.form.end_time': 'Slut',
  'dashboard.card_work_hours.form.hours': 'Timer',
  'dashboard.card_work_hours.form.hours.suffix': 'timer',
  'dashboard.card_work_hours.form.intro': 'Registér arbejdstid for {day}',
  'dashboard.card_work_hours.form.intro.day': '{day} d. {date}',
  'dashboard.card_work_hours.form.start_time': 'Start',
  'dashboard.card_work_hours.form.submit': 'Gem',
  'dashboard.card_work_hours.go_to_page': 'Alle registreringer',
  'dashboard.card_work_hours.title': 'Arbejdstid',
  'dashboard.choice_card.switcher.form.edit.choice': 'Forside',
  'dashboard.choice_card.switcher.form.edit.submit': 'Vælg forside',
  'dashboard.choice_card.switcher.intro':
    'Du kan vælge hvad der skal vises per standard på forsiden, så du hurtigere kan få adgang til den ønskede funktion du bruger mest.',
  'dashboard.choice_card.switcher.title': 'Standardforside',
  'dashboard.company_switch.logged_in_at': 'hos',
  'dashboard.company_switch.switch_company': 'Skift arbejdsplads',
  'dashboard.company_switch.you_are_logged_in_as': 'Du er logget ind som',
  'dashboard.swipe.swipe_link': 'Se dine Swipes',
  'dashboard.swipe.swipe_link_available': 'Swipe {amount} nu',

  'date_picker.select_end_date': 'Vælg slutdato',
  'date_picker.select_start_date': 'Vælg startdato',
  'date_picker.today': 'I dag',

  'documents.added': 'Tilføjet',
  'documents.create_document': 'Tilføj dokument',
  'documents.deletion_confirmation': 'Er du sikker på du vil slette dokumentet?',
  'documents.description': 'Beskrivelse',
  'documents.document_category': 'Kategori',
  'documents.documents': 'Dokumenter',
  'documents.edit_document': 'Redigér dokument',
  'documents.no_documents_yet': 'Du har ikke nogen dokumenter endnu.',
  'documents.payslips': 'Lønsedler',
  'documents.upload_new': 'Nyt dokument',
  'documents.view_document': 'Se dokument',

  'error_message.message.accounting_api_key_invalid': 'API-nøglen er ikke gyldig.',
  'error_message.message.device_token_not_found': 'Din adgangsnøgle er udløbet, log ind igen.',
  'error_message.message.email_token_not_found': 'Email-link er ikke længere gyldigt.',
  'error_message.message.employee_contract_invalid': 'Kontrakten er ikke gyldig.',
  'error_message.message.employee_contract_not_found': 'Kontrakten kunne ikke findes.',
  'error_message.message.employee_employed_in_company': 'Medarbejder eksisterer allerede.',
  'error_message.message.employee_image_format_invalid': 'Filtypen for medarbejderens billede er ikke gyldig.',
  'error_message.message.employee_immutable': 'Medarbejderen kan ikke ændres.',
  'error_message.message.employee_time_registration_excess_hours_pr_day': 'Tidsregistreringen er ikke gyldig.',
  'error_message.message.employment_number_is_not_unique': 'Medarbejdernummer er allerede i brug.',
  'error_message.message.has_pending_swipe':
    'Der er allerede en udestående Swipe, vent til den er udbetalt, eller slet den før du opretter en ny.',
  'error_message.message.import_could_not_log_in': 'Brugernavn eller adgangskode er ikke korrekt.',
  'error_message.message.leave_registration_cannot_be_vacation_day':
    'Fravær kan ikke registreres i weekender eller på en fri- eller helligdag.',
  'error_message.message.login_throttled': 'Du har forsøgt at logge ind for mange i træk.  Vent.',
  'error_message.message.login_throttled.details':
    'Du har forsøgt at logge ind for mange i træk.  Vent til kl. {time} og prøv igen.',
  'error_message.message.mfa_wrong_response': 'Forkert kode, prøv igen og vær sikker på at koden ikke er udløbet.',
  'error_message.message.password_must_be_at_least_8_characters': 'Adgangskoden skal være mindst 8 tegn.',
  'error_message.message.password_must_not_be_listed_in_public_data_breach':
    'Adgangskoden findes i et offentligt læk af logindoplysninger.',
  'error_message.message.production_unit_already_exists': 'Produktionsenheden eksisterer allerede.',
  'error_message.message.time_registration_already_exists': 'Der er allerede registreret for denne periode.',
  'error_message.message.unsupported_file_format': 'Filtypen er ikke tilladt.',
  'error_message.message.user_is_not_employee': 'Din bruger er ikke oprettet som medarbejder.',
  'error_message.message.user_not_found': 'Brugeren kunne ikke findes.',
  'error_message.message.workflow_cannot_be_cancelled':
    'Du kan ikke rette din profil lige nu, kontakt din arbejdsgiver.',
  'error_message.type.company_already_exists': 'Virksomheden eksisterer allerede.',
  'error_message.type.company_feature_missing': 'Denne funktion kræver Premium.',
  'error_message.type.email_already_exists': 'E-mailadressen eksisterer allerede.',
  'error_message.type.email_token_expired':
    'Din invitation er udløbet. Kontakt din leder for at få tilsendt en ny invitation fra Salary.',
  'error_message.type.internal_server_error': 'Der opstod en uventet fejl. Vi arbejder på sagen.',
  'error_message.type.wrong_password': 'Adgangskoden er ikke korrekt.',

  'fees.create_fee': 'Opret honorar',
  'fees.disposition_date': 'Betalingsdato',
  'fees.no_fees_yet': 'Ingen honorarer endnu',
  'fees.title': 'Beskrivelse',
  'fees.update_fee': 'Opdatér honorar',

  'footer.car_allowance': 'Kørsel',
  'footer.dashboard': 'Oversigt',
  'footer.documents': 'Dokumenter',
  'footer.fees': 'Honorar',
  'footer.flex_registration': 'Flekstid',
  'footer.leave_registration': 'Fravær',
  'footer.overtime_registration': 'Afspadsering',
  'footer.project_registration': 'Projekttid',
  'footer.reimbursement_vouchers': 'Udlæg',
  'footer.salary_registration': 'Løntillæg',
  'footer.time_registration': 'Tidsregistrering',
  'footer.work_hours_registration': 'Arbejdstid',

  'front_page_choice.car_allowance': 'Kørsel',
  'front_page_choice.default': 'Standard',
  'front_page_choice.leave_registration': 'Fravær',
  'front_page_choice.one_time_pay_commission': 'Honorar',
  'front_page_choice.one_time_pay_travel_allowance': 'Diæt',
  'front_page_choice.project_registration': 'Projekttid',
  'front_page_choice.reimbursement_vouchers': 'Udlæg',
  'front_page_choice.salary_registration': 'Løntillæg',
  'front_page_choice.time_box_registration.flex': 'Fleks',
  'front_page_choice.time_box_registration.overtime': 'Afspadsering',
  'front_page_choice.time_registration': 'Tidsregistrering',
  'front_page_choice.work_hours': 'Arbejdstid',

  'gender.female': 'Kvinde',
  'gender.male': 'Mand',

  'header.go_back': 'Gå tilbage',

  'leave_durations.full_day': 'Hel dag',
  'leave_durations.half_day': 'Halv dag',
  'leave_durations.other': 'Anden værdi',
  'leave_durations.quarter_day': 'Kvart dag',
  'leave_durations.three_quarters_day': 'Trekvart dag',

  'leave_registration.additional_leave_balances_balance': 'Feriefridage til rådighed',
  'leave_registration.cycle_header': 'Udløbende feriedage',
  'leave_registration.cycle_header_balance': 'Dage der udløber',
  'leave_registration.cycle_header_expire': 'Udløbsdato',
  'leave_registration.cycles_hide': 'Skjul',
  'leave_registration.cycles_show': 'Detaljeret information om fremtidige feriedage',
  'leave_registration.first_day': 'Første dag',
  'leave_registration.future_calculation_explanation_more': 'For mere information, se',
  'leave_registration.future_calculation_explanation_more_link': 'vores vejledning om ferieloven',
  'leave_registration.future_calculation_explanation_part1':
    'Ferieåret løber fra 1. september til 31. august.  De optjente feriedage udløber den efterfølgende 31. december.  Dvs. at feriedage optjent i f.eks. september {startYear} udløber 31. december {endYear}.',
  'leave_registration.future_calculation_explanation_part2':
    'Man optjener 2,08 feriedage per måned.  På ferieårets 12 måneder optjener man i alt 5 ugers ferie, eller 25 feriedage.',
  'leave_registration.future_calculation_explanation_part2_alternative':
    'Man optjener normalt 2,08 feriedage per måned.  På ferieårets 12 måneder optjener man normalt i alt 5 ugers ferie, eller 25 feriedage.  Da du tjener {days} feriedage om året, vil din månedlige optjening være en anden.',
  'leave_registration.future_calculation_explanation_part3':
    'Her laver vi en beregning af dine fremtidige feriedagesaldi i løbet af de næste 12 måneder, så du bedre kan planlægge din fremtidige ferie.  Beregningerne indeholder dine fremtidige registreringer.',
  'leave_registration.future_calculation_explanation_part3_extra':
    'Beregningerne tager forbehold for at du optjener {days} per ferieår.',
  'leave_registration.future_calculation_show': 'Forklaring',
  'leave_registration.future_disclaimer':
    'De fremtidige feriesaldi er vejledende, de forudsætter dit ansættelsesforhold ikke ændres.',
  'leave_registration.future_disclaimer_excess': 'Tallene inkluderer ikke lånte feriedage.',
  'leave_registration.future_disclaimer_transfer': 'Tallene inkluderer ikke evt. overførte feriedage.',
  'leave_registration.futures_header': 'Fremskrivning af ferie med løn',
  'leave_registration.futures_header_balance': 'Feriedagesaldo',
  'leave_registration.futures_header_date': 'Måned',
  'leave_registration.futures_header_taken': 'Brug',
  'leave_registration.last_day': 'Sidste dag',
  'leave_registration.leave_balances_balance': 'Resterende optjente feriedage',
  'leave_registration.leave_balances_excess': 'Feriedage du må låne',
  'leave_registration.leave_balances_expiring': 'Udløber d. {date}',
  'leave_registration.leave_balances_future': 'Fremtidige registreringer',
  'leave_registration.leave_balances_title': 'Feriesaldi',
  'leave_registration.leave_balances_total': 'Feriedage til rådighed',
  'leave_registration.leave_statistics': 'Fraværsstatistik',
  'leave_registration.no_leave_registrations_yet': 'Du har ikke registreret fravær endnu.',
  'leave_registration.period': 'Periode',
  'leave_registration.personal_days_balance': 'Omsorgsdage til rådighed',
  'leave_registration.register_leave': 'Registrer fravær',
  'leave_registration.sub_type_day_off': 'Fridagstype',
  'leave_registration.sub_type_other': 'Undertype',
  'leave_registration.sub_type_sick_day': 'Sygedagstype',

  'leave_statistics.card.contracted_work_days': 'Teoretiske arbejdsdage',
  'leave_statistics.card.days_off': 'Fraværsdage',
  'leave_statistics.card.office_days': 'Kontordage',
  'leave_statistics.card.work_days': 'Faktiske arbejdsdage',
  'leave_statistics.disclaimer':
    'Tallene er vejledende, de antager at din kontrakts arbejdsuge er korrekt sat op, og at du registrerer hjemmearbejdsdage korrekt.',
  'leave_statistics.explain_link': 'Forklaring',
  'leave_statistics.explanation.part1':
    'Fraværsstatistik viser arbejds-, fraværs- og kontordage per kalendermåned for et givent år.',
  'leave_statistics.explanation.part2':
    '{contracted_work_days} er de arbejdsdage som din kontrakt foreskriver du skal arbejde, såfremt der er intet fravær eller andre uoverenstemmelser.',
  'leave_statistics.explanation.part3':
    '{work_days} er de arbejdsdage som du faktisk har arbejdet, når fravær, orlov, m.v. er trukket fra.',
  'leave_statistics.explanation.part4':
    '{office_days} er de dage hvor du har været på kontoret, altså dine faktiske arbejdsdage fratrukket dine hjemmearbejdsdage.',
  'leave_statistics.explanation.part5':
    '{days_off} er dage hvor du ikke har arbejdet, enten pga. ferie, sygdom eller anden fravær.',
  'leave_statistics.explanation.part6':
    'Kontordage kan bruges når du skal indberette befordringsfradrag på din årsopgørelse til SKAT.',
  'leave_statistics.selector': 'Se fraværsstatistik for et andet år:',
  'leave_statistics.title': 'Fraværsstatistik for {year}',

  'leave_type_names.day_off': 'Fridag',
  'leave_type_names.day_off_employer_paid': 'Fridag (arbejdsgiverbetalt)',
  'leave_type_names.optional_vacation': 'Feriefridag',
  'leave_type_names.optional_vacation_accrual': 'Feriefridag',
  'leave_type_names.personal_day': 'Omsorgsdag',
  'leave_type_names.personal_day_accrual': 'Omsorgsdag',
  'leave_type_names.remote_work': 'Hjemmearbejde',
  'leave_type_names.sick_day_paid': 'Sygedag',
  'leave_type_names.sick_day_paid_employer_paid': 'Sygedag (arbejdsgiverbetalt)',
  'leave_type_names.unpaid_day_off': 'Ubetalt fravær',
  'leave_type_names.vacation_accrual': 'Feriedag med løn',
  'leave_type_names.vacation_fund': 'Feriedag uden løn',
  'leave_type_names.vacation_fund_pension': 'Feriedag uden løn',
  'leave_type_names.vacation_no_pay': 'Feriedag uden løn',
  'leave_type_names.vacation_paid': 'Feriedag med løn',
  'leave_type_names.vacation_paid_additional': 'Feriedag med løn',

  'leave.sub_type.anniversary': 'Jubilæum',
  'leave.sub_type.child_first_sick_day': 'Barns første sygedag',
  'leave.sub_type.child_second_sick_day': 'Barns anden sygedag',
  'leave.sub_type.course': 'Kursus',
  'leave.sub_type.covid_19': 'COVID-19',
  'leave.sub_type.mourning_day': 'Sorgedag',
  'leave.sub_type.moving_day': 'Flyttedag',
  'leave.sub_type.paragraph_56': '§ 56-aftale',
  'leave.sub_type.parental_leave': 'Arbejdsgiverbetalt barsel',
  'leave.sub_type.parental_leave_after_2_weeks': 'Forældreorlov',
  'leave.sub_type.parental_leave_first_2_weeks': 'Fædreorlov',
  'leave.sub_type.parental_leave_last_4_weeks_before_term': '4 uger før termin (alm. forløb)',
  'leave.sub_type.parental_leave_sickness': 'Graviditetsbetinget sygdom',
  'leave.sub_type.time_off_in_lieu': 'Afspadsering',

  'login.enter_your_code_below': 'Indtast din {desc} herunder.',
  'login.forgot_password': 'Glemt adgangskode?',
  'login.no_account_contact_employer': 'Hvis du ikke har en konto, kontakt din arbejdsgiver.',
  'login.one_time_code': 'engangskode',
  'login.remember_device': 'Husk enhed i 30 dage',
  'login.remember_me': 'Husk mig',
  'login.two_factor_code': 'tofaktorgodkendelseskode',
  'login.use_one_time_code': 'Benyt engangskode',

  'mfa_channels.none': 'Ingen',
  'mfa_channels.sms': 'SMS',
  'mfa_channels.totp': 'Authenticator-app',

  'payslip_preview.payslip_for': 'Lønseddel for',

  'payslips.download_all': 'Hent alle gennemførte lønsedler som én PDF',
  'payslips.future_payslips': 'Kommende lønsedler',
  'payslips.future_payslips_note': 'Disse lønsedler er ikke færdigkørt endnu, så der tages forbehold for ændringer.',
  'payslips.no_payslips_yet': 'Du har ikke nogen lønsedler endnu.',

  'profile.calendar_include_holidays': 'Medtag helligdage i fraværskalender',
  'profile.cpr_number': 'CPR-nummer',
  'profile.go_to_car_allowance': 'Kørselsregistrering',
  'profile.go_to_documents': 'Se dine lønsedler og dokumenter',
  'profile.language': 'Vælg sprog til lønseddel og grænseflade',
  'profile.language_da': 'Dansk',
  'profile.language_en': 'Engelsk',
  'profile.link_to_subscribe_to_leave_calendar': 'Link til at abonnere på fraværskalender',
  'profile.log_out_from_account': 'Log ud af konto',
  'profile.next_of_kin': 'Pårørende',
  'profile.notifications': 'Notifikationer',
  'profile.onboarding_alert': 'Udfyld dine informationer, for at færdiggøre din oprettelse.',
  'profile.payslips_are_sent_to': 'Lønsedler sendes til',
  'profile.profile_saved': 'Din profil er gemt',
  'profile.relation': 'Relation',
  'profile.sent_by_email': 'E-mail',
  'profile.sent_by_sms': 'SMS',
  'profile.sent_to_digital_post': 'Digital post ({version})',

  'register.create_new_user': 'Opret ny bruger',
  'register.create_user': 'Opret bruger',
  'register.i_have_read_the_terms': 'Jeg har læst betingelserne',

  'reimbursement_voucher_approval_states.approved': 'Godkendt',
  'reimbursement_voucher_approval_states.draft': 'Kladde',
  'reimbursement_voucher_approval_states.ready': 'Afventer godkendelse',

  'reimbursement_vouchers.add_voucher': 'Tilføj udlæg',
  'reimbursement_vouchers.add_voucher_camera': 'Tag billede',
  'reimbursement_vouchers.add_voucher_file': 'Vælg fil',
  'reimbursement_vouchers.amount_in_foreign_currency': 'Beløb i udenlandsk valuta',
  'reimbursement_vouchers.ask_for_immediate_pay': 'Anmod om ekspresoverførsel',
  'reimbursement_vouchers.company_card_only_accounting': 'Firmakort (kun bogføring)',
  'reimbursement_vouchers.cost_center': 'Omkostningssted',
  'reimbursement_vouchers.date_is_old': 'NB: Kvitteringsdato er mere end 12 måneder gammel.',
  'reimbursement_vouchers.description_of_company_card_for_accounting': 'Beskrivelse af firmakort til regnskab',
  'reimbursement_vouchers.disabled':
    'Udlægshåndtering er ikke slået til. Kontakt din arbejdsgiver for at begynde at registrere udlæg selv.',
  'reimbursement_vouchers.disburse_amount': 'Beløb i danske kroner',
  'reimbursement_vouchers.disburseable': 'til udbetaling',
  'reimbursement_vouchers.disburseable_type': 'Udlægstype',
  'reimbursement_vouchers.disburseable.immediate_pay': 'afventer straksudbetaling',
  'reimbursement_vouchers.disburseable.paid': 'udbetalt',
  'reimbursement_vouchers.expense_category': 'Udgiftskategori',
  'reimbursement_vouchers.foreign_currency': 'Udenlandsk valuta',
  'reimbursement_vouchers.immediate_pay_label':
    'Med ekspresoverførsel kan du få dit udlæg udbetalt så hurtigt som muligt',
  'reimbursement_vouchers.no_cost_center': 'Intet omkostningssted',
  'reimbursement_vouchers.no_department': 'Egen afdeling',
  'reimbursement_vouchers.no_reimbursement_vouchers_yet': 'Du har ikke oprettet nogen udlæg endnu.',
  'reimbursement_vouchers.own_voucher_disburseable': 'Eget udlæg (til udbetaling)',
  'reimbursement_vouchers.ready_for_approval': 'Klar til godkendelse',
  'reimbursement_vouchers.send_for_approval': 'Send til godkendelse',
  'reimbursement_vouchers.title': 'Udlægshåndtering',

  'request_password.check_your_email_for_new_password': 'Tjek din e-mail for ny adgangskode.',
  'request_password.enter_email_to_request_password':
    'Indtast din email og modtag din nye adgangskode indenfor 2 minutter.',
  'request_password.forgot_password': 'Glemt adgangskode',
  'request_password.request_new_password': 'Anmod om ny adgangskode',

  'reset_password.approve_new_password': 'Godkend ny adgangskode',
  'reset_password.create_new_password': 'Opret ny adgangskode',
  'reset_password.your_password_has_been_reset': 'Din adgangskode er blevet nulstillet. Log ind herunder.',

  'salary_registration.awaiting_payout': 'Antal der afventer udbetaling',
  'salary_registration.no_salary_registrations_yet': 'Du har ikke registeret løntillæg endnu.',
  'salary_registration.of': 'à',
  'salary_registration.quantity': 'Antal',
  'salary_registration.register_salary': 'Registrér løntillæg',

  'swipe.calculating': 'Vi er ved at beregne hvor meget du kan Swipe for',
  'swipe.date_format': 'D/M',
  'swipe.disabled':
    'Swipe er ikke slået til. Kontakt din arbejdsgiver for at begynde at få udbetalt løn når du har lyst.',
  'swipe.fee_note': 'Du skal betale et gebyr på {fee}, når du swiper. {link}.',
  'swipe.fee_note.more_information': 'Gebyrinformation',
  'swipe.fee.explanation.cap': 'Der er sat en øvre grænse på {cap}, så du aldrig kan betale mere end for et Swipe.',
  'swipe.fee.explanation.deduction': 'Gebyret trækkes fra din nettoløn, og vil fremstå på din lønseddel.',
  'swipe.fee.explanation.line_1.each':
    'Din arbejdsgiver har besluttet at du skal betale et gebyr på {fee} for at bruge Swipe-funktionen. Du vil opkrævet for hvert Swipe.',
  'swipe.fee.explanation.line_1.period':
    'Din arbejdsgiver har besluttet at du skal betale et gebyr på {fee} for at bruge Swipe-funktionen. Du vil opkrævet for dit første Swipe i en lønperiode (dvs. én gang per lønseddel).',
  'swipe.go_to_history': 'Se dine tidligere Swipes her',
  'swipe.go_to_swipe': 'Du kan stadig Swipe {amount} her',
  'swipe.immediate_choice': 'Næste bankdag (gratis)',
  'swipe.instant_choice': 'Straksudbetaling ({cost} fratrukket din nettoløn)',
  'swipe.just_swiped': 'Du har Swipet {amount}!',
  'swipe.just_swiped_approved': 'Du vil modtage dine penge {when}.',
  'swipe.just_swiped_needs_approval': 'Den skal godkendes, før den kan blive overført.',
  'swipe.just_swiped_on_the_way': 'Lønnen er på vej!',
  'swipe.just_swiped_return': 'Tilbage til forsiden',
  'swipe.need_approval': 'Afventer godkendelse',
  'swipe.no_swipes_yet': 'Du har ikke brugt Swipe endnu.',
  'swipe.on_day': '{day} morgen',
  'swipe.one_time_pay_title': 'Swipe d. {date}',
  'swipe.over_limit':
    'Du er over grænsen på {amount} for autogodkendelse, så denne Swipe skal afvente manuel godkendelse af din arbejdsgiver.',
  'swipe.paid': 'Udbetalt',
  'swipe.payout_option_instructions_1':
    'Din arbejdsgiver tillader du kan vælge straksudbetaling (inden for fem minutter), men mod at {cost} bliver fratrukket din nettoløn.',
  'swipe.payout_option_instructions_2': 'Du kan også vælge at få pengene om 1-2 bankdage gratis.',
  'swipe.pending': 'Afventer',
  'swipe.swipe_amount_adjustment_instructions': 'Vælg det beløb du vil have udbetalt',
  'swipe.swipe_available': 'tilgængelig',
  'swipe.swipe_instructions': 'Swipe for at få beløbet udbetalt',
  'swipe.swipe_introduction_available': 'Du har {amount} til rådighed, vælg det beløb du vil Swipe, og Swipe!',
  'swipe.swipe_nothing_available': 'Du har ingen penge tilgængelig til Swipe.',
  'swipe.swipe_select_amount': 'Vælg et beløb over 0 kr. for at Swipe',
  'swipe.title': 'Swipe',
  'swipe.today': 'i dag',
  'swipe.unknown_day': 'før eller siden',
  'swipe.want_to_cancel': 'Vil du annullere din Swipe?',

  'time_registration_types.hours': 'Arbejdstimer',
  'time_registration_types.leave': 'Fravær',

  'time_registration.awaiting_payout': 'Afventer udbetaling',
  'time_registration.break_minutes': 'Pause',
  'time_registration.detailed_time_registration_disabled':
    'Detaljeret tidsregistrering er ikke slået til. Kontakt din arbejdsgiver for at begynde at registrere tid selv.',
  'time_registration.flex_balance': 'Flekstidsaldo',
  'time_registration.flex_earned': 'Optjent',
  'time_registration.flex_used': 'Brugt',
  'time_registration.no_historic_time_registrations': 'Du har ingen historiske registreringer.',
  'time_registration.no_time_registrations_yet': 'Du har ikke registreret tid endnu.',
  'time_registration.number_of_hours': 'Antal timer',
  'time_registration.overtime_earned': 'Optjent',
  'time_registration.overtime_used': 'Brugt',
  'time_registration.project': 'Projekt',
  'time_registration.project_registration': 'Projekttid',
  'time_registration.project_registration_disabled': 'Du er ikke tilknyttet nogle projekter.',
  'time_registration.register_flex_time': 'Registrer flekstid',
  'time_registration.register_overtime': 'Registrer afspadsering',
  'time_registration.register_project_time': 'Registrer tid',
  'time_registration.register_time': 'Registrer timer',
  'time_registration.register_work_hours': 'Registrer arbejdstid',
  'time_registration.time_registration': 'Tidsregistrering',
  'time_registration.work_hours_registration': 'Arbejdstid',
  'time_registration.work_hours_registration_disabled': 'Du kan ikke registere arbejdstid.',

  'validation.account_number_is_invalid': 'Kontonummer er ugyldigt',
  'validation.address_is_required': 'Adresse skal indtastes',
  'validation.amount_is_invalid': 'Beløb er ugyldigt',
  'validation.amount_is_required': 'Beløb skal indtastes',
  'validation.birth_date_is_invalid': 'Fødselsdato er ugyldig',
  'validation.birth_date_is_required': 'Fødselsdato skal indtastes',
  'validation.break_minutes_is_invalid': 'Pause er ugyldigt',
  'validation.break_minutes_longer_than_possible': 'Pause må ikke være længere end muligt',
  'validation.break_minutes_must_be_integer': 'Pause skal være et heltal',
  'validation.city_is_required': 'By skal indtastes',
  'validation.code_is_required': 'Kode skal indtastes',
  'validation.country_cannot_be_gb': 'For det Forenede Kongerige, skal du enten vælge Storbritannien eller Nordirland',
  'validation.country_code_is_invalid': 'Landekode er ugyldig',
  'validation.country_code_is_required': 'Landekode skal vælges',
  'validation.country_must_be_chosen': 'Land skal vælges',
  'validation.cpr_number_is_invalid': 'CPR-nummer er ugyldigt',
  'validation.cpr_number_is_required': 'CPR-nummer skal indtastes',
  'validation.currency_is_invalid': 'Valuta er ugyldigt',
  'validation.currency_is_required': 'Valuta skal indtastes',
  'validation.date_is_before': 'Dato er før {date}',
  'validation.date_is_invalid': 'Dato er ugyldig',
  'validation.date_is_required': 'Dato er påkrævet',
  'validation.days_is_invalid': 'Dage er ugyldig',
  'validation.days_is_required': 'Dage er påkrævet',
  'validation.description_is_required': 'Beskrivelse skal indtastes',
  'validation.disburse_amount_is_invalid': 'Beløb i danske kroner er ugyldig',
  'validation.disburse_amount_is_required': 'Beløb i danske kroner er påkrævet',
  'validation.disposition_date_is_required': 'Betalingsdato skal vælges',
  'validation.distance_is_invalid': 'Afstand er ugyldig',
  'validation.distance_is_required': 'Afstand skal indtastes',
  'validation.document_category_is_required': 'Kategori skal vælges',
  'validation.duration_must_be_chosen': 'Varighed skal vælges',
  'validation.email_is_required': 'E-mail skal indtastes',
  'validation.end_is_invalid': 'Slut er ugyldigt',
  'validation.end_is_required': 'Slut skal indtastes',
  'validation.end_must_be_after_start': 'Slut skal være efter start',
  'validation.enter_the_required_fields_correctly': 'Udfyld venligst de påkrævede felter korrekt',
  'validation.from_is_required': 'Fra skal indtastes',
  'validation.gender_is_required': 'Køn skal vælges',
  'validation.license_plate_is_required': 'Nummerplade skal indtastes',
  'validation.name_is_required': 'Navn skal indtastes',
  'validation.number_of_hours_is_invalid': 'Antal timer er ugydligt',
  'validation.number_of_hours_is_required': 'Antal timer skal indtastes',
  'validation.one_time_code_is_required': 'Engangskode skal indtastes',
  'validation.password_is_required': 'Adgangskode skal indtastes',
  'validation.password_must_be_8_characters': 'Adgangskode skal være mindst 8 tegn',
  'validation.period_is_required': 'Periode er påkrævet',
  'validation.phone_number_is_invalid': 'Telefonnummer er ugyldigt',
  'validation.post_code_is_invalid': 'Postnr. er ugyldigt',
  'validation.post_code_is_required': 'Postnr. skal indtastes',
  'validation.project_must_be_chosen': 'Projekt skal vælges',
  'validation.purpose_is_required': 'Formål skal indtastes',
  'validation.quantity_is_invalid': 'Antal er ugydligt',
  'validation.quantity_is_required': 'Antal skal indtastes',
  'validation.reg_number_is_invalid': 'Reg.nr. er ugyldigt',
  'validation.start_is_invalid': 'Start er ugyldigt',
  'validation.start_is_required': 'Start skal indtastes',
  'validation.start_must_be_before_end': 'Start skal være før slut',
  'validation.to_is_required': 'Til skal indtastes',
  'validation.type_must_be_chosen': 'Type skal vælges',

  'widgets.your_information_is_awaiting_approval_from_your_employer':
    'Dine informationer afventer godkendelse fra din arbejdsgiver.',

  'work_hours_registration_tab.alert.success': 'Arbejdstid er gemt',
  'work_hours_registration_tab.alert.template_deleted': 'Skabelon er slettet',
  'work_hours_registration_tab.alert.template_none_saved': 'Du har nu ingen skabelon',
  'work_hours_registration_tab.alert.template_saved': 'Skabelon er gemt',
  'work_hours_registration_tab.simple_form.delete': 'Slet',
  'work_hours_registration_tab.simple_form.delete_with_company': 'Slet egen',
  'work_hours_registration_tab.simple_form.disabled_intro':
    'Nedenstående skabelon berør dig lige nu, men det er virksomhedsskabelonen.  Den kan du ikke rette i, men du kan lave din egen skabelon ved at klikke på ny skabelon.',
  'work_hours_registration_tab.simple_form.intro': 'Indtast hvor mange timer der er arbejdet per dag.',
  'work_hours_registration_tab.simple_form.none': 'Ingen',
  'work_hours_registration_tab.simple_form.submit': 'Gem uge',
  'work_hours_registration_tab.simple_form.submit.template': 'Gem skabelon',
  'work_hours_registration_tab.simple_form.template_intro':
    'Her kan du indtaste en skabelon, som automatisk opretter arbejdstids for dig.  Du kan også vælge at have ingen skabelon, så du altid manuelt skal indtaste arbejdstid.',
  'work_hours_registration_tab.simple_form.time_based.break': 'Pause',
  'work_hours_registration_tab.simple_form.time_based.end': 'Slut',
  'work_hours_registration_tab.simple_form.time_based.header.break': 'Pause',
  'work_hours_registration_tab.simple_form.time_based.header.break.invalid': 'Pause er ugyldig',
  'work_hours_registration_tab.simple_form.time_based.header.break.longer_before_total':
    'Pause må ikke være længere tid end muligt',
  'work_hours_registration_tab.simple_form.time_based.header.break.must_be_integer': 'Pause skal være et heltal',
  'work_hours_registration_tab.simple_form.time_based.header.day': 'Ugedag',
  'work_hours_registration_tab.simple_form.time_based.header.end': 'Slut',
  'work_hours_registration_tab.simple_form.time_based.header.start': 'Start',
  'work_hours_registration_tab.simple_form.time_based.intro': 'Pauser er angivet i minutter.',
  'work_hours_registration_tab.simple_form.time_based.start': 'Start',
  'work_hours_registration_tab.simple_form.unlock': 'Ny skabelon',
  'work_hours_registration_tab.template': 'Skabelon',
  'work_hours_registration_tab.week.detailed_toggle.detailed': 'Detaljeret',
  'work_hours_registration_tab.week.detailed_toggle.simple': 'Simpel',
  'work_hours_registration_tab.week.new_registration': 'Registér arbejdstid',
  'work_hours_registration_tab.week.next': 'Næste uge',
  'work_hours_registration_tab.week.previous': 'Forrige uge',
  'work_hours_registration_tab.week.summary': 'Ugentlige timer: {summary}',
  'work_hours_registration_tab.week.summary.count.many': '{number} timer',
  'work_hours_registration_tab.week.summary.count.one': '{number} time',
  'work_hours_registration_tab.week.this_week': 'Denne uge',
  'work_hours_registration_tab.week.title': 'Uge {week_number} ({week_from} - {week_to})',
  'work_hours_registration_tab.week.title.template': 'Ugeskabelon',
}

export default {
  'common.account_number': 'Account number',
  'common.address': 'Address',
  'common.amount': 'Amount',
  'common.approved': 'Approved',
  'common.are_you_sure': 'Are you sure?',
  'common.bank_information': 'Bank information',
  'common.birth_date': 'Birth date',
  'common.city': 'City',
  'common.close': 'Close',
  'common.code': 'Code',
  'common.cost_center': 'Cost center',
  'common.country': 'Country',
  'common.country_code': 'Country code',
  'common.currency': 'Currency',
  'common.date': 'Date',
  'common.day': 'Day',
  'common.days': 'Days',
  'common.department': 'Department',
  'common.description': 'Description',
  'common.duration': 'Duration',
  'common.email': 'Email',
  'common.end': 'End',
  'common.friday': 'Friday',
  'common.from': 'From',
  'common.gender': 'Gender',
  'common.go_back': 'Go back',
  'common.log_in': 'Log in',
  'common.log_out': 'Log out',
  'common.monday': 'Monday',
  'common.month': 'Month',
  'common.name': 'Name',
  'common.no': 'No',
  'common.no_cost_center': 'No cost center',
  'common.no_department': 'Own department',
  'common.none': 'None',
  'common.not_approved': 'Not approved',
  'common.note': 'Note',
  'common.one_time_code': 'One time code',
  'common.password': 'Password',
  'common.phone_number': 'Phone number',
  'common.post_code': 'Post code',
  'common.reg_number': 'Routing no.',
  'common.saturday': 'Saturday',
  'common.save': 'Save',
  'common.save_changes': 'Save changes',
  'common.select_country': 'Select country',
  'common.select_gender': 'Select gender',
  'common.show_history': 'Show history',
  'common.start': 'Start',
  'common.sunday': 'Sunday',
  'common.thursday': 'Thursday',
  'common.to': 'To',
  'common.tuesday': 'Tuesday',
  'common.type': 'Type',
  'common.unknown': 'Unknown',
  'common.wednesday': 'Wednesday',
  'common.year': 'Year',
  'common.yes': 'Yes',

  'date.date_short_format': 'd MMM yyyy',
  'date.day_month': 'd MMMM',
  'date.day_month_short': 'd MMM',
  'date.day_of_month': 'd',

  'unit.days.many': 'days',
  'unit.days.one': 'day',
  'unit.hours.many': 'hours',
  'unit.hours.one': 'hour',
  'unit.km': 'km',
  'unit.minutes.many': 'minutes',
  'unit.minutes.one': 'minute',

  'app.version_deprecated': 'Your current app version is out of date. Please update to the latest version.',

  'car_allowance.also_create_return_trip': 'Also create return trip',
  'car_allowance.avoid_ferries': 'Avoid ferries',
  'car_allowance.distance': 'Distance',
  'car_allowance.enter_to_from': 'enter to/from',
  'car_allowance.license_plate': 'Number plate',
  'car_allowance.no_car_allowances_yet': 'You have not registered any transport allowance yet.',
  'car_allowance.no_historic_car_allowances': 'There are no entries in your transport allowance history.',
  'car_allowance.no_return_route_found': 'No return route could be found between the two points.',
  'car_allowance.no_route_found': 'No route could be found between the two points.',
  'car_allowance.purpose': 'Purpose',
  'car_allowance.rate': 'Rate',
  'car_allowance.rate.above_limit': 'Rate cannot be more than {limit}',
  'car_allowance.rate.invalid': 'Rate is invalid',
  'car_allowance.rate.suffix': 'kr.',
  'car_allowance.register_car_allowance': 'Register transport allowance',
  'car_allowance.suggested_route': 'Suggested route',
  'car_allowance.table.rate_format': 'Rate: {rate}',

  'country_codes.ad': 'Andorra',
  'country_codes.ae': 'United Arab Emirates',
  'country_codes.af': 'Afghanistan',
  'country_codes.ag': 'Antigua and Barbuda',
  'country_codes.ai': 'Anguilla',
  'country_codes.al': 'Albania',
  'country_codes.am': 'Armenia',
  'country_codes.ao': 'Angola',
  'country_codes.aq': 'Antarctica',
  'country_codes.ar': 'Argentina',
  'country_codes.as': 'American Samoa',
  'country_codes.at': 'Austria',
  'country_codes.au': 'Australia',
  'country_codes.aw': 'Aruba',
  'country_codes.ax': 'Åland Islands',
  'country_codes.az': 'Azerbaijan',
  'country_codes.ba': 'Bosnia and Herzegovina',
  'country_codes.bb': 'Barbados',
  'country_codes.bd': 'Bangladesh',
  'country_codes.be': 'Belgium',
  'country_codes.bf': 'Burkina Faso',
  'country_codes.bg': 'Bulgaria',
  'country_codes.bh': 'Bahrain',
  'country_codes.bi': 'Burundi',
  'country_codes.bj': 'Benin',
  'country_codes.bl': 'Saint Barthélemy',
  'country_codes.bm': 'Bermuda',
  'country_codes.bn': 'Brunei',
  'country_codes.bo': 'Bolivia',
  'country_codes.bq': 'Bonaire, Sint Eustatius and Saba',
  'country_codes.br': 'Brazil',
  'country_codes.bs': 'Bahamas',
  'country_codes.bt': 'Bhutan',
  'country_codes.bv': 'Bouvet Island',
  'country_codes.bw': 'Botswana',
  'country_codes.by': 'Belarus',
  'country_codes.bz': 'Belize',
  'country_codes.ca': 'Canada',
  'country_codes.cc': 'Cocos (Keeling) Islands',
  'country_codes.cd': 'Congo (Democratic Republic of the)',
  'country_codes.cf': 'Central African Republic',
  'country_codes.cg': 'Congo',
  'country_codes.ch': 'Switzerland',
  'country_codes.ci': "Côte d'Ivoire",
  'country_codes.ck': 'Cook Islands',
  'country_codes.cl': 'Chile',
  'country_codes.cm': 'Cameroon',
  'country_codes.cn': 'China',
  'country_codes.co': 'Colombia',
  'country_codes.cr': 'Costa Rica',
  'country_codes.cu': 'Cuba',
  'country_codes.cv': 'Cabo Verde',
  'country_codes.cw': 'Curaçao',
  'country_codes.cx': 'Christmas Island',
  'country_codes.cy': 'Cyprus',
  'country_codes.cz': 'Czechia',
  'country_codes.de': 'Germany',
  'country_codes.dj': 'Djibouti',
  'country_codes.dk': 'Denmark',
  'country_codes.dm': 'Dominica',
  'country_codes.do': 'Dominican Republic',
  'country_codes.dz': 'Algeria',
  'country_codes.ec': 'Ecuador',
  'country_codes.ee': 'Estonia',
  'country_codes.eg': 'Egypt',
  'country_codes.eh': 'Western Sahara',
  'country_codes.er': 'Eritrea',
  'country_codes.es': 'Spain',
  'country_codes.et': 'Ethiopia',
  'country_codes.fi': 'Finland',
  'country_codes.fj': 'Fiji',
  'country_codes.fk': 'Falkland Islands (Malvinas)',
  'country_codes.fm': 'Micronesia',
  'country_codes.fo': 'Faroe Islands',
  'country_codes.fr': 'France',
  'country_codes.ga': 'Gabon',
  'country_codes.gb': 'United Kingdom of Great Britain and Northern Ireland',
  'country_codes.gd': 'Grenada',
  'country_codes.ge': 'Georgia',
  'country_codes.gf': 'French Guiana',
  'country_codes.gg': 'Guernsey',
  'country_codes.gh': 'Ghana',
  'country_codes.gi': 'Gibraltar',
  'country_codes.gl': 'Greenland',
  'country_codes.gm': 'Gambia',
  'country_codes.gn': 'Guinea',
  'country_codes.gp': 'Guadeloupe',
  'country_codes.gq': 'Equatorial Guinea',
  'country_codes.gr': 'Greece',
  'country_codes.gs': 'South Georgia and the South Sandwich Islands',
  'country_codes.gt': 'Guatemala',
  'country_codes.gu': 'Guam',
  'country_codes.gw': 'Guinea-Bissau',
  'country_codes.gy': 'Guyana',
  'country_codes.hk': 'Hong Kong',
  'country_codes.hm': 'Heard Island and McDonald Islands',
  'country_codes.hn': 'Honduras',
  'country_codes.hr': 'Croatia',
  'country_codes.ht': 'Haiti',
  'country_codes.hu': 'Hungary',
  'country_codes.id': 'Indonesia',
  'country_codes.ie': 'Ireland',
  'country_codes.il': 'Israel',
  'country_codes.im': 'Isle of Man',
  'country_codes.in': 'India',
  'country_codes.io': 'British Indian Ocean Territory',
  'country_codes.iq': 'Iraq',
  'country_codes.ir': 'Iran',
  'country_codes.is': 'Iceland',
  'country_codes.it': 'Italy',
  'country_codes.je': 'Jersey',
  'country_codes.jm': 'Jamaica',
  'country_codes.jo': 'Jordan',
  'country_codes.jp': 'Japan',
  'country_codes.ke': 'Kenya',
  'country_codes.kg': 'Kyrgyzstan',
  'country_codes.kh': 'Cambodia',
  'country_codes.ki': 'Kiribati',
  'country_codes.km': 'Comoros',
  'country_codes.kn': 'Saint Kitts and Nevis',
  'country_codes.kp': 'Korea, North',
  'country_codes.kr': 'Korea, South',
  'country_codes.kw': 'Kuwait',
  'country_codes.ky': 'Cayman Islands',
  'country_codes.kz': 'Kazakhstan',
  'country_codes.la': 'Laos',
  'country_codes.lb': 'Lebanon',
  'country_codes.lc': 'Saint Lucia',
  'country_codes.li': 'Liechtenstein',
  'country_codes.lk': 'Sri Lanka',
  'country_codes.lr': 'Liberia',
  'country_codes.ls': 'Lesotho',
  'country_codes.lt': 'Lithuania',
  'country_codes.lu': 'Luxembourg',
  'country_codes.lv': 'Latvia',
  'country_codes.ly': 'Libya',
  'country_codes.ma': 'Morocco',
  'country_codes.mc': 'Monaco',
  'country_codes.md': 'Moldova',
  'country_codes.me': 'Montenegro',
  'country_codes.mf': 'Saint Martin (French part)',
  'country_codes.mg': 'Madagascar',
  'country_codes.mh': 'Marshall Islands',
  'country_codes.mk': 'North Macedonia',
  'country_codes.ml': 'Mali',
  'country_codes.mm': 'Myanmar',
  'country_codes.mn': 'Mongolia',
  'country_codes.mo': 'Macao',
  'country_codes.mp': 'Northern Mariana Islands',
  'country_codes.mq': 'Martinique',
  'country_codes.mr': 'Mauritania',
  'country_codes.ms': 'Montserrat',
  'country_codes.mt': 'Malta',
  'country_codes.mu': 'Mauritius',
  'country_codes.mv': 'Maldives',
  'country_codes.mw': 'Malawi',
  'country_codes.mx': 'Mexico',
  'country_codes.my': 'Malaysia',
  'country_codes.mz': 'Mozambique',
  'country_codes.na': 'Namibia',
  'country_codes.nc': 'New Caledonia',
  'country_codes.ne': 'Niger',
  'country_codes.nf': 'Norfolk Island',
  'country_codes.ng': 'Nigeria',
  'country_codes.ni': 'Nicaragua',
  'country_codes.nl': 'Netherlands',
  'country_codes.no': 'Norway',
  'country_codes.np': 'Nepal',
  'country_codes.nr': 'Nauru',
  'country_codes.nu': 'Niue',
  'country_codes.nz': 'New Zealand',
  'country_codes.om': 'Oman',
  'country_codes.pa': 'Panama',
  'country_codes.pe': 'Peru',
  'country_codes.pf': 'French Polynesia',
  'country_codes.pg': 'Papua New Guinea',
  'country_codes.ph': 'Philippines',
  'country_codes.pk': 'Pakistan',
  'country_codes.pl': 'Poland',
  'country_codes.pm': 'Saint Pierre and Miquelon',
  'country_codes.pn': 'Pitcairn',
  'country_codes.pr': 'Puerto Rico',
  'country_codes.ps': 'Palestine',
  'country_codes.pt': 'Portugal',
  'country_codes.pw': 'Palau',
  'country_codes.py': 'Paraguay',
  'country_codes.qa': 'Qatar',
  'country_codes.re': 'Réunion',
  'country_codes.ro': 'Romania',
  'country_codes.rs': 'Serbia',
  'country_codes.ru': 'Russia',
  'country_codes.rw': 'Rwanda',
  'country_codes.sa': 'Saudi Arabia',
  'country_codes.sb': 'Solomon Islands',
  'country_codes.sc': 'Seychelles',
  'country_codes.sd': 'Sudan',
  'country_codes.se': 'Sweden',
  'country_codes.sg': 'Singapore',
  'country_codes.sh': 'Saint Helena, Ascension and Tristan da Cunha',
  'country_codes.si': 'Slovenia',
  'country_codes.sj': 'Svalbard and Jan Mayen',
  'country_codes.sk': 'Slovakia',
  'country_codes.sl': 'Sierra Leone',
  'country_codes.sm': 'San Marino',
  'country_codes.sn': 'Senegal',
  'country_codes.so': 'Somalia',
  'country_codes.sr': 'Suriname',
  'country_codes.ss': 'South Sudan',
  'country_codes.st': 'Sao Tome and Principe',
  'country_codes.sv': 'El Salvador',
  'country_codes.sx': 'Sint Maarten (Dutch part)',
  'country_codes.sy': 'Syria',
  'country_codes.sz': 'Eswatini',
  'country_codes.tc': 'Turks and Caicos Islands',
  'country_codes.td': 'Chad',
  'country_codes.tf': 'French Southern Territories',
  'country_codes.tg': 'Togo',
  'country_codes.th': 'Thailand',
  'country_codes.tj': 'Tajikistan',
  'country_codes.tk': 'Tokelau',
  'country_codes.tl': 'Timor-Leste',
  'country_codes.tm': 'Turkmenistan',
  'country_codes.tn': 'Tunisia',
  'country_codes.to': 'Tonga',
  'country_codes.tr': 'Turkey',
  'country_codes.tt': 'Trinidad and Tobago',
  'country_codes.tv': 'Tuvalu',
  'country_codes.tw': 'Taiwan',
  'country_codes.tz': 'Tanzania',
  'country_codes.ua': 'Ukraine',
  'country_codes.ug': 'Uganda',
  'country_codes.um': 'United States Minor Outlying Islands',
  'country_codes.us': 'United States of America',
  'country_codes.uy': 'Uruguay',
  'country_codes.uz': 'Uzbekistan',
  'country_codes.va': 'Holy See',
  'country_codes.vc': 'Saint Vincent and the Grenadines',
  'country_codes.ve': 'Venezuela',
  'country_codes.vg': 'Virgin Islands (British)',
  'country_codes.vi': 'Virgin Islands (U.S.)',
  'country_codes.vn': 'Vietnam',
  'country_codes.vu': 'Vanuatu',
  'country_codes.wf': 'Wallis and Futuna',
  'country_codes.ws': 'Samoa',
  'country_codes.xi': 'Northern Ireland',
  'country_codes.xu': 'Great Britain (United Kingdom without Northern Ireland)',
  'country_codes.ye': 'Yemen',
  'country_codes.yt': 'Mayotte',
  'country_codes.za': 'South Africa',
  'country_codes.zm': 'Zambia',
  'country_codes.zw': 'Zimbabwe',

  'dashboard.card_car_allowance.alert.success': 'Trip saved',
  'dashboard.card_car_allowance.go_to_page': 'All trips',
  'dashboard.card_car_allowance.register_new': 'New trip',
  'dashboard.card_car_allowance.summary.count.many': '{number} trips',
  'dashboard.card_car_allowance.summary.count.one': '{number} trip',
  'dashboard.card_car_allowance.summary.km': '{number} km',
  'dashboard.card_car_allowance.summary.line_1': 'Kilometres today, {day}:',
  'dashboard.card_car_allowance.summary.line_2': '{kilometres} over {number}.',
  'dashboard.card_car_allowance.title': 'Transport allowance',
  'dashboard.card_default.gross_pay': 'Gross pay',
  'dashboard.card_default.hide_pay': 'Hide pay',
  'dashboard.card_default.latest_paycheck_from': 'Latest pay cheque from',
  'dashboard.card_default.net_pay': 'Net pay',
  'dashboard.card_default.next_paycheck_from': 'Next pay cheque from',
  'dashboard.card_default.show_pay': 'Show pay',
  'dashboard.card_flex_time_registrations.alert.success': 'Flex registration saved',
  'dashboard.card_flex_time_registrations.go_to_page': 'All registrations',
  'dashboard.card_flex_time_registrations.register_new': 'New registration',
  'dashboard.card_flex_time_registrations.title': 'Flex time',
  'dashboard.card_leave_registration.alert.success': 'Absence saved',
  'dashboard.card_leave_registration.go_to_page': 'All absence',
  'dashboard.card_leave_registration.register_new': 'New registration',
  'dashboard.card_leave_registration.title': 'Absence for {date}',
  'dashboard.card_overtime_registrations.alert.success': 'Overtime registration saved',
  'dashboard.card_overtime_registrations.go_to_page': 'All registrations',
  'dashboard.card_overtime_registrations.register_new': 'New registration',
  'dashboard.card_overtime_registrations.title': 'Overtime',
  'dashboard.card_project_registrations.alert.success': 'Project registration saved',
  'dashboard.card_project_registrations.go_to_page': 'All registrations',
  'dashboard.card_project_registrations.register_new': 'New registration',
  'dashboard.card_project_registrations.title': 'Project time',
  'dashboard.card_salary_registration.alert.success': 'Supplement saved',
  'dashboard.card_salary_registration.go_to_page': 'All supplements',
  'dashboard.card_salary_registration.register_new': 'New supplement',
  'dashboard.card_salary_registration.summary.line': '{quantity} × {rate} = {total}',
  'dashboard.card_salary_registration.summary.total': 'Total: {total}',
  'dashboard.card_salary_registration.title': 'Supplement',
  'dashboard.card_time_registrations.alert.success': 'Registration saved',
  'dashboard.card_time_registrations.go_to_page': 'All registrations',
  'dashboard.card_time_registrations.register_new': 'New registration',
  'dashboard.card_time_registrations.title': 'Time registration',
  'dashboard.card_work_hours.alert.success': 'Working hours saved',
  'dashboard.card_work_hours.form.break_minutes': 'Pause',
  'dashboard.card_work_hours.form.break_minutes.suffix': 'minutes',
  'dashboard.card_work_hours.form.end_time': 'End',
  'dashboard.card_work_hours.form.hours': 'Hours',
  'dashboard.card_work_hours.form.hours.suffix': 'hours',
  'dashboard.card_work_hours.form.intro': 'Register working hours for {day}',
  'dashboard.card_work_hours.form.intro.day': '{day} the {date}',
  'dashboard.card_work_hours.form.start_time': 'Start',
  'dashboard.card_work_hours.form.submit': 'Save',
  'dashboard.card_work_hours.go_to_page': 'All registrations',
  'dashboard.card_work_hours.title': 'Working hours',
  'dashboard.choice_card.switcher.form.edit.choice': 'Front page',
  'dashboard.choice_card.switcher.form.edit.submit': 'Select front page',
  'dashboard.choice_card.switcher.intro':
    'You can select what should be shown per default on the front page, so you can quicker access the feature you use the most.',
  'dashboard.choice_card.switcher.title': 'Standard front page',
  'dashboard.company_switch.logged_in_at': 'at',
  'dashboard.company_switch.switch_company': 'Switch company',
  'dashboard.company_switch.you_are_logged_in_as': 'You are logged in as',
  'dashboard.swipe.swipe_link': 'See your Swipes',
  'dashboard.swipe.swipe_link_available': 'Swipe {amount} now',

  'date_picker.select_end_date': 'Select end date',
  'date_picker.select_start_date': 'Select start date',
  'date_picker.today': 'Today',

  'documents.added': 'Added',
  'documents.create_document': 'Add document',
  'documents.deletion_confirmation': 'Are you sure you wish to delete the document?',
  'documents.description': 'Description',
  'documents.document_category': 'Category',
  'documents.documents': 'Documents',
  'documents.edit_document': 'Edit document',
  'documents.no_documents_yet': 'You do not have any documents yet.',
  'documents.payslips': 'Payslips',
  'documents.upload_new': 'New document',
  'documents.view_document': 'View document',

  'error_message.message.accounting_api_key_invalid': 'The API key is invalid.',
  'error_message.message.device_token_not_found': 'Your token has expired, log in again.',
  'error_message.message.email_token_not_found': 'Email link is no longer valid.',
  'error_message.message.employee_contract_invalid': 'The contract is invalid.',
  'error_message.message.employee_contract_not_found': 'The contract could not be found.',
  'error_message.message.employee_employed_in_company': 'The employee already exists.',
  'error_message.message.employee_image_format_invalid': 'The file type for the employee photo is invalid.',
  'error_message.message.employee_immutable': 'The employee could not be changed.',
  'error_message.message.employee_time_registration_excess_hours_pr_day': 'The time registration is invalid.',
  'error_message.message.employment_number_is_not_unique': 'The employee number is already in use.',
  'error_message.message.has_pending_swipe':
    'There is already a pending Swipe, wait for it to be paid or delete it before creating a new one.',
  'error_message.message.import_could_not_log_in': 'Username or password is incorrect.',
  'error_message.message.leave_registration_cannot_be_vacation_day':
    'Leave cannot be registered on weekends, holidays or days off.',
  'error_message.message.login_throttled': 'You have attempted to log in too many times in a row.  Wait.',
  'error_message.message.login_throttled.details':
    'You have attempted to log in too many times in a row.  Wait until {time} and try again.',
  'error_message.message.mfa_wrong_response': 'Wrong code, try again and ensure the code is not expired.',
  'error_message.message.password_must_be_at_least_8_characters': 'Password must be at least 8 characters.',
  'error_message.message.password_must_not_be_listed_in_public_data_breach':
    'Password was found in a public breach of log in information.',
  'error_message.message.production_unit_already_exists': 'The production unit already exists.',
  'error_message.message.time_registration_already_exists': 'Registration already exists for this period.',
  'error_message.message.unsupported_file_format': 'File type not allowed.',
  'error_message.message.user_is_not_employee': 'Your user is not an employee.',
  'error_message.message.user_not_found': 'The user could not be found.',
  'error_message.message.workflow_cannot_be_cancelled':
    'You cannot change your profile right now, contact your employer.',
  'error_message.type.company_already_exists': 'Company already exists.',
  'error_message.type.company_feature_missing': 'This feature requires Premium.',
  'error_message.type.email_already_exists': 'Email already exists.',
  'error_message.type.email_token_expired':
    'Your invitation is expired. Contact your employer to receive a new invitation from Salary.',
  'error_message.type.internal_server_error': 'An unexpected error occurred. We are working to solve it.',
  'error_message.type.wrong_password': 'The password is incorrect.',

  'fees.create_fee': 'Create fee',
  'fees.disposition_date': 'Disposition date',
  'fees.no_fees_yet': 'No fees created yet',
  'fees.title': 'Description',
  'fees.update_fee': 'Update fee',

  'footer.car_allowance': 'Transport allowance',
  'footer.dashboard': 'Dashboard',
  'footer.documents': 'Documents',
  'footer.fees': 'Fees',
  'footer.flex_registration': 'Flex time',
  'footer.leave_registration': 'Absence',
  'footer.overtime_registration': 'Overtime',
  'footer.project_registration': 'Project time',
  'footer.reimbursement_vouchers': 'Receipts',
  'footer.salary_registration': 'Supplements',
  'footer.time_registration': 'Hours',
  'footer.work_hours_registration': 'Working time',

  'front_page_choice.car_allowance': 'Transport allowance',
  'front_page_choice.default': 'Standard',
  'front_page_choice.leave_registration': 'Absence',
  'front_page_choice.one_time_pay_commission': 'Fee',
  'front_page_choice.one_time_pay_travel_allowance': 'Travel allowance',
  'front_page_choice.project_registration': 'Project',
  'front_page_choice.reimbursement_vouchers': 'Receipts',
  'front_page_choice.salary_registration': 'Supplements',
  'front_page_choice.time_box_registration.flex': 'Flex time',
  'front_page_choice.time_box_registration.overtime': 'Overtime',
  'front_page_choice.time_registration': 'Time registration',
  'front_page_choice.work_hours': 'Working hours',

  'gender.female': 'Female',
  'gender.male': 'Male',

  'header.go_back': 'Go back',

  'leave_durations.full_day': 'Full day',
  'leave_durations.half_day': 'Half day',
  'leave_durations.other': 'Other value',
  'leave_durations.quarter_day': 'Quarter day',
  'leave_durations.three_quarters_day': 'Three quarters day',

  'leave_registration.additional_leave_balances_balance': 'Extra holidays available',
  'leave_registration.cycle_header': 'Expiring holidays',
  'leave_registration.cycle_header_balance': 'Days expiring',
  'leave_registration.cycle_header_expire': 'Expiration date',
  'leave_registration.cycles_hide': 'Hide',
  'leave_registration.cycles_show': 'Detailed information about future holidays',
  'leave_registration.first_day': 'First day',
  'leave_registration.future_calculation_explanation_more': 'For more information, see',
  'leave_registration.future_calculation_explanation_more_link': 'our guide about the Danish Holiday Law (in Danish)',
  'leave_registration.future_calculation_explanation_part1':
    'The holiday year runs from 1 September to 31 August.  The earned holidays expire the following 31 December.  E.g. holidays earned in September {startYear} expires 31 December {endYear}.',
  'leave_registration.future_calculation_explanation_part2':
    "One earn 2.08 holidays per month.  During the holiday year's 12 months, one earns a total of 5 weeks, or 25 holidays.",
  'leave_registration.future_calculation_explanation_part2_alternative':
    "One normally earn 2.08 holidays per month.  During the holiday year's 12 months. one normally earns a total of 5 weeks, or 25 holidays.  Since you earn {days} holidays per year, your monthly rate will therefore be another.",
  'leave_registration.future_calculation_explanation_part3':
    'Here we are making calculations for your future holiday balance for the next 12 months, to help you plan your holiday.  The calculation includes your future registrations.',
  'leave_registration.future_calculation_explanation_part3_extra':
    'The calculations take into consideration, that you earn {days} per year.',
  'leave_registration.future_calculation_show': 'Explanation',
  'leave_registration.future_disclaimer':
    'The future holiday balances are only advisory, they assume your employment does not change.',
  'leave_registration.future_disclaimer_excess': 'The numbers do not include borrowed holidays.',
  'leave_registration.future_disclaimer_transfer': 'The numbers do not include potential transferred holidays.',
  'leave_registration.futures_header': 'Future paid holiday balances',
  'leave_registration.futures_header_balance': 'Balance',
  'leave_registration.futures_header_date': 'Month',
  'leave_registration.futures_header_taken': 'Use',
  'leave_registration.last_day': 'Last day',
  'leave_registration.leave_balances_balance': 'Remaining earned holidays',
  'leave_registration.leave_balances_excess': 'Holidays you may borrow',
  'leave_registration.leave_balances_expiring': 'Expiring {date}',
  'leave_registration.leave_balances_future': 'Future registrations',
  'leave_registration.leave_balances_title': 'Holiday balances',
  'leave_registration.leave_balances_total': 'Holidays available',
  'leave_registration.leave_statistics': 'Absence statistics',
  'leave_registration.no_leave_registrations_yet': 'You have not registered any absence yet.',
  'leave_registration.period': 'Period',
  'leave_registration.personal_days_balance': 'Personal days available',
  'leave_registration.register_leave': 'Register absence',
  'leave_registration.sub_type_day_off': 'Day off type',
  'leave_registration.sub_type_other': 'Subtype',
  'leave_registration.sub_type_sick_day': 'Sick day type',

  'leave_statistics.card.contracted_work_days': 'Theoretical workdays',
  'leave_statistics.card.days_off': 'Absence days',
  'leave_statistics.card.office_days': 'Office days',
  'leave_statistics.card.work_days': 'Actual workdays',
  'leave_statistics.disclaimer':
    "The numbers are intended as a guide, they assume your contract's workweek is correct and that you report remote work correctly.",
  'leave_statistics.explain_link': 'Explanation',
  'leave_statistics.explanation.part1':
    'Absence statistics show you workdays, absence and office days per calendar month for a selected year.',
  'leave_statistics.explanation.part2':
    '{contracted_work_days} are workdays according to your contract, without considering absence, leave or other abnormalities.',
  'leave_statistics.explanation.part3':
    '{work_days} are the workdays you have actually worked, when absence, leave, etc. has been deducted.',
  'leave_statistics.explanation.part4':
    '{office_days} are those days where you went to the office, so your actual workdays minus your remote work (i.e. working from home) registrations.',
  'leave_statistics.explanation.part5':
    '{days_off} are the days you have not worked, either due to holiday, sickness or other absence.',
  'leave_statistics.explanation.part6':
    'Office days can be used when you report your transport deduction (befordringsfradrag) on your tax assessment notice (årsopgørelse) to SKAT.',
  'leave_statistics.selector': 'See absence statistics for another year:',
  'leave_statistics.title': 'Absence statistics for {year}',

  'leave_type_names.day_off': 'Day off',
  'leave_type_names.day_off_employer_paid': 'Day off (employer paid)',
  'leave_type_names.optional_vacation': 'Extra holiday',
  'leave_type_names.optional_vacation_accrual': 'Extra holiday',
  'leave_type_names.personal_day': 'Personal day',
  'leave_type_names.personal_day_accrual': 'Personal day',
  'leave_type_names.remote_work': 'Working from home',
  'leave_type_names.sick_day_paid': 'Sick day',
  'leave_type_names.sick_day_paid_employer_paid': 'Sick day (employer paid)',
  'leave_type_names.unpaid_day_off': 'Unpaid day off',
  'leave_type_names.vacation_accrual': 'Paid holiday',
  'leave_type_names.vacation_fund': 'Unpaid holiday',
  'leave_type_names.vacation_fund_pension': 'Unpaid holiday',
  'leave_type_names.vacation_no_pay': 'Unpaid holiday',
  'leave_type_names.vacation_paid': 'Paid holiday',
  'leave_type_names.vacation_paid_additional': 'Paid holiday',

  'leave.sub_type.anniversary': 'Anniversary',
  'leave.sub_type.child_first_sick_day': "Child's first sick day",
  'leave.sub_type.child_second_sick_day': "Child's second sick day",
  'leave.sub_type.course': 'Course',
  'leave.sub_type.covid_19': 'COVID-19',
  'leave.sub_type.mourning_day': 'Mourning day',
  'leave.sub_type.moving_day': 'Moving day',
  'leave.sub_type.paragraph_56': '§ 56 agreement',
  'leave.sub_type.parental_leave': 'Employer paid parental leave',
  'leave.sub_type.parental_leave_after_2_weeks': 'Parental leave',
  'leave.sub_type.parental_leave_first_2_weeks': 'Paternity leave',
  'leave.sub_type.parental_leave_last_4_weeks_before_term': 'Four weeks before term (normal process)',
  'leave.sub_type.parental_leave_sickness': 'Pregnancy-related sickness',
  'leave.sub_type.time_off_in_lieu': 'Time off in lieu',

  'login.enter_your_code_below': 'Enter your {desc} below.',
  'login.forgot_password': 'Forgot password?',
  'login.no_account_contact_employer': 'If you do not have an account, contact your employer.',
  'login.one_time_code': 'one time code',
  'login.remember_device': 'Remember device for 30 days',
  'login.remember_me': 'Remember me',
  'login.two_factor_code': 'two factor code',
  'login.use_one_time_code': 'Use one time code',

  'mfa_channels.none': 'None',
  'mfa_channels.sms': 'SMS',
  'mfa_channels.totp': 'Authenticator app',

  'payslip_preview.payslip_for': 'Payslip for',

  'payslips.download_all': 'Download all completed pay slips as a single PDF',
  'payslips.future_payslips': 'Future payslips',
  'payslips.future_payslips_note': 'These payslips have not yet been completed, so they may change.',
  'payslips.no_payslips_yet': 'You do not have any payslips yet.',

  'profile.calendar_include_holidays': 'Include holidays in absence calendar',
  'profile.cpr_number': 'CPR number',
  'profile.go_to_car_allowance': 'Transport allowance',
  'profile.go_to_documents': 'See your payslips and documents',
  'profile.language': 'Select language for payslip and interface',
  'profile.language_da': 'Danish',
  'profile.language_en': 'English',
  'profile.link_to_subscribe_to_leave_calendar': 'Link to subscribe to absence calendar',
  'profile.log_out_from_account': 'Log out from account',
  'profile.next_of_kin': 'Next of kin',
  'profile.notifications': 'Notifications',
  'profile.onboarding_alert': 'Fill in your information to complete your onboarding.',
  'profile.payslips_are_sent_to': 'Payslips are sent to',
  'profile.profile_saved': 'Your profile has been saved',
  'profile.relation': 'Relation',
  'profile.sent_by_email': 'Email',
  'profile.sent_by_sms': 'SMS',
  'profile.sent_to_digital_post': 'Digital post ({version})',

  'register.create_new_user': 'Create new user',
  'register.create_user': 'Create user',
  'register.i_have_read_the_terms': 'I have read the terms',

  'reimbursement_voucher_approval_states.approved': 'Approved',
  'reimbursement_voucher_approval_states.draft': 'Draft',
  'reimbursement_voucher_approval_states.ready': 'Awaiting approval',

  'reimbursement_vouchers.add_voucher': 'Add receipt',
  'reimbursement_vouchers.add_voucher_camera': 'Take photo',
  'reimbursement_vouchers.add_voucher_file': 'Choose file',
  'reimbursement_vouchers.amount_in_foreign_currency': 'Amount in foreign currency',
  'reimbursement_vouchers.ask_for_immediate_pay': 'Ask for immediate pay',
  'reimbursement_vouchers.company_card_only_accounting': 'Company card (only accounting)',
  'reimbursement_vouchers.cost_center': 'Cost center',
  'reimbursement_vouchers.date_is_old': 'Note: Receipt date is more than 12 months old.',
  'reimbursement_vouchers.description_of_company_card_for_accounting': 'Description of company card for accounting',
  'reimbursement_vouchers.disabled':
    'Reimbursement registration is not enabled. Contact your employer to begin registering reimbursements yourself.',
  'reimbursement_vouchers.disburse_amount': 'Amount in Danish kroner',
  'reimbursement_vouchers.disburseable': 'disburseable',
  'reimbursement_vouchers.disburseable_type': 'Reimbursement type',
  'reimbursement_vouchers.disburseable.immediate_pay': 'awaiting express payment',
  'reimbursement_vouchers.disburseable.paid': 'paid out',
  'reimbursement_vouchers.expense_category': 'Expense category',
  'reimbursement_vouchers.foreign_currency': 'Foreign currency',
  'reimbursement_vouchers.immediate_pay_label':
    'With immediate pay, you can get your receipt paid out as soon as possible',
  'reimbursement_vouchers.no_cost_center': 'No cost center',
  'reimbursement_vouchers.no_department': 'Own department',
  'reimbursement_vouchers.no_reimbursement_vouchers_yet': 'You have not added any receipts yet.',
  'reimbursement_vouchers.own_voucher_disburseable': 'Own receipt (disburseable)',
  'reimbursement_vouchers.ready_for_approval': 'Ready for approval',
  'reimbursement_vouchers.send_for_approval': 'Send for approval',
  'reimbursement_vouchers.title': 'Reimbursements',

  'request_password.check_your_email_for_new_password': 'Check your email to get a new password.',
  'request_password.enter_email_to_request_password':
    'Enter your email and receive your new password within 2 minutes.',
  'request_password.forgot_password': 'Forgot password',
  'request_password.request_new_password': 'Request a new password',

  'reset_password.approve_new_password': 'Approve new password',
  'reset_password.create_new_password': 'Create new password',
  'reset_password.your_password_has_been_reset': 'Your password has been reset. Log in below.',

  'salary_registration.awaiting_payout': 'Amount awaiting payout',
  'salary_registration.no_salary_registrations_yet': 'You have not registered supplements.',
  'salary_registration.of': 'of',
  'salary_registration.quantity': 'Quantity',
  'salary_registration.register_salary': 'Register supplement',

  'swipe.calculating': 'We are currently calculating what you can Swipe for',
  'swipe.date_format': 'D MMMM',
  'swipe.disabled': 'Swipe is not enabled. Contact your employer to begin getting paid when you want.',
  'swipe.fee_note': 'You will pay a fee of {fee}, when you swipe. {link}.',
  'swipe.fee_note.more_information': 'Fee information',
  'swipe.fee.explanation.cap':
    'There is an upper limit of {cap}, so you can never be charged more than that for a Swipe.',
  'swipe.fee.explanation.deduction': 'The fee is deducted from your pay cheque, and will appear on your pay slip.',
  'swipe.fee.explanation.line_1.each':
    'Your employer has decided that you will a fee of {fee} to use the Swipe feature. You will be charged for every Swipe.',
  'swipe.fee.explanation.line_1.period':
    'Your employer has decided that you will a fee of {fee} to use the Swipe feature. You will be charged for your first Swipe in a pay period (i.e. once per pay slip).',
  'swipe.go_to_history': 'See your previous Swipes here',
  'swipe.go_to_swipe': 'You can still Swipe for {amount} here',
  'swipe.immediate_choice': 'Next bank day (free)',
  'swipe.instant_choice': 'Instant payout ({cost} from pay cheque)',
  'swipe.just_swiped': 'You Swiped for {amount}!',
  'swipe.just_swiped_approved': 'You should receive your money {when}.',
  'swipe.just_swiped_needs_approval': 'Your Swipe will need approval before it can be transferred.',
  'swipe.just_swiped_on_the_way': 'Your salary is on its way!',
  'swipe.just_swiped_return': 'Return to front',
  'swipe.need_approval': 'Awaiting approval',
  'swipe.no_swipes_yet': 'You have not used Swipe yet.',
  'swipe.on_day': '{day} morning',
  'swipe.one_time_pay_title': 'Swipe at {date}',
  'swipe.over_limit':
    'You are above the limit at {amount} for auto approval, so this Swipe will require manual approval from your employer.',
  'swipe.paid': 'Paid',
  'swipe.payout_option_instructions_1':
    'Your employer allows you to receive instant payment (within five minutes), but at a cost of {cost} deducted from your pay cheque.',
  'swipe.payout_option_instructions_2': 'You can also choose in 1-2 bank days at no cost.',
  'swipe.pending': 'Pending',
  'swipe.swipe_amount_adjustment_instructions': 'Select the amount you want paid out',
  'swipe.swipe_available': 'available',
  'swipe.swipe_instructions': 'Swipe to get it paid out',
  'swipe.swipe_introduction_available': 'You have {amount} available, select the amount you wish to Swipe, and Swipe!',
  'swipe.swipe_nothing_available': 'You have no money available for Swipe.',
  'swipe.swipe_select_amount': 'Select an amount above 0 kr. to Swipe',
  'swipe.title': 'Swipe',
  'swipe.today': 'today',
  'swipe.unknown_day': 'at some point in the future',
  'swipe.want_to_cancel': 'Do you want to cancel your Swipe?',

  'time_registration_types.hours': 'Work hours',
  'time_registration_types.leave': 'Absence',

  'time_registration.awaiting_payout': 'Awaiting payout',
  'time_registration.break_minutes': 'Break length',
  'time_registration.detailed_time_registration_disabled':
    'Detailed time registration is not enabled. Contact your employer to start registering time yourself.',
  'time_registration.flex_balance': 'Flex time balance',
  'time_registration.flex_earned': 'Earned',
  'time_registration.flex_used': 'Used',
  'time_registration.no_historic_time_registrations': 'You have no historic time registrations.',
  'time_registration.no_time_registrations_yet': 'You have not registered any time yet.',
  'time_registration.number_of_hours': 'Number of hours',
  'time_registration.overtime_earned': 'Earned',
  'time_registration.overtime_used': 'Used',
  'time_registration.project': 'Project',
  'time_registration.project_registration': 'Project time',
  'time_registration.project_registration_disabled': 'You are not assigned to any project.',
  'time_registration.register_flex_time': 'Register flex time',
  'time_registration.register_overtime': 'Register overtime',
  'time_registration.register_project_time': 'Register time',
  'time_registration.register_time': 'Register hours',
  'time_registration.register_work_hours': 'Register working time',
  'time_registration.time_registration': 'Time registration',
  'time_registration.work_hours_registration': 'Working hours',
  'time_registration.work_hours_registration_disabled': 'You cannot register working hours.',

  'validation.account_number_is_invalid': 'Account number is invalid',
  'validation.address_is_required': 'Address is required',
  'validation.amount_is_invalid': 'Amount is invalid',
  'validation.amount_is_required': 'Amount is required',
  'validation.birth_date_is_invalid': 'Birth date is invalid',
  'validation.birth_date_is_required': 'Birth date is required',
  'validation.break_minutes_is_invalid': 'Break length is invalid',
  'validation.break_minutes_longer_than_possible': 'Break length may not be longer than possible',
  'validation.break_minutes_must_be_integer': 'Break length must be a whole number',
  'validation.city_is_required': 'City is required',
  'validation.code_is_required': 'Code is invalid',
  'validation.country_cannot_be_gb': 'For the United Kingdom, you must select either Great Britain or Northern Ireland',
  'validation.country_code_is_invalid': 'Country code is invalid',
  'validation.country_code_is_required': 'Country code is required',
  'validation.country_must_be_chosen': 'Country must be chosen',
  'validation.cpr_number_is_invalid': 'CPR number is invalid',
  'validation.cpr_number_is_required': 'CPR number is required',
  'validation.currency_is_invalid': 'Currency is invalid',
  'validation.currency_is_required': 'Currency is required',
  'validation.date_is_before': 'Date is before {date}',
  'validation.date_is_invalid': 'Date is invalid',
  'validation.date_is_required': 'Date is required',
  'validation.days_is_invalid': 'Days are invalid',
  'validation.days_is_required': 'Days are required',
  'validation.description_is_required': 'Description is required',
  'validation.disburse_amount_is_invalid': 'Amount in Danish kroner is invalid',
  'validation.disburse_amount_is_required': 'Amount in Danish kroner is required',
  'validation.disposition_date_is_required': 'Disposition date is required',
  'validation.distance_is_invalid': 'Distance is invalid',
  'validation.distance_is_required': 'Distance is required',
  'validation.document_category_is_required': 'Category is required',
  'validation.duration_must_be_chosen': 'Duration must be chosen',
  'validation.email_is_required': 'Email is required',
  'validation.end_is_invalid': 'End is invalid',
  'validation.end_is_required': 'End is required',
  'validation.end_must_be_after_start': 'End must be after start',
  'validation.enter_the_required_fields_correctly': 'Please enter the required fields correctly',
  'validation.from_is_required': 'From is required',
  'validation.gender_is_required': 'Gender is required',
  'validation.license_plate_is_required': 'Number plate is required',
  'validation.name_is_required': 'Name is required',
  'validation.number_of_hours_is_invalid': 'Number of hours is invalid',
  'validation.number_of_hours_is_required': 'Number of hours is required',
  'validation.one_time_code_is_required': 'One time code is invalid',
  'validation.password_is_required': 'Password is required',
  'validation.password_must_be_8_characters': 'Password must be at least 8 characters',
  'validation.period_is_required': 'Period is required',
  'validation.phone_number_is_invalid': 'Phone number is invalid',
  'validation.post_code_is_invalid': 'Post code is invalid',
  'validation.post_code_is_required': 'Post code is required',
  'validation.project_must_be_chosen': 'Project must be chosen',
  'validation.purpose_is_required': 'Purpose is required',
  'validation.quantity_is_invalid': 'Quantity is invalid',
  'validation.quantity_is_required': 'Quantity is required',
  'validation.reg_number_is_invalid': 'Routing number is invalid',
  'validation.start_is_invalid': 'Start is invalid',
  'validation.start_is_required': 'Start is required',
  'validation.start_must_be_before_end': 'Start must be before end',
  'validation.to_is_required': 'To is required',
  'validation.type_must_be_chosen': 'Type must be chosen',

  'widgets.your_information_is_awaiting_approval_from_your_employer':
    'Your information is awaiting approval from your employer.',

  'work_hours_registration_tab.alert.success': 'Working hours have been saved',
  'work_hours_registration_tab.alert.template_deleted': 'Template deleted',
  'work_hours_registration_tab.alert.template_none_saved': 'You now have know template',
  'work_hours_registration_tab.alert.template_saved': 'Template has been saved',
  'work_hours_registration_tab.simple_form.delete': 'Delete',
  'work_hours_registration_tab.simple_form.delete_with_company': 'Delete own',
  'work_hours_registration_tab.simple_form.disabled_intro':
    'The following template is what generates your registrations, but it is the company wide template that you cannot edit.  You can however create your own template by clicking new template.',
  'work_hours_registration_tab.simple_form.intro': 'Enter the number of hours worked per day.',
  'work_hours_registration_tab.simple_form.none': 'No template',
  'work_hours_registration_tab.simple_form.submit': 'Save week',
  'work_hours_registration_tab.simple_form.submit.template': 'Save template',
  'work_hours_registration_tab.simple_form.template_intro':
    'You can create a template here that will automatically register working hours for you.  You can also decide to have no template, if you wish to enter them all manually.',
  'work_hours_registration_tab.simple_form.time_based.break': 'Break',
  'work_hours_registration_tab.simple_form.time_based.end': 'End',
  'work_hours_registration_tab.simple_form.time_based.header.break': 'Break',
  'work_hours_registration_tab.simple_form.time_based.header.break.invalid': 'Break is invalid',
  'work_hours_registration_tab.simple_form.time_based.header.break.longer_before_total':
    'Break cannot be longer than the entire registration',
  'work_hours_registration_tab.simple_form.time_based.header.break.must_be_integer': 'Break must be an integer',
  'work_hours_registration_tab.simple_form.time_based.header.day': 'Day',
  'work_hours_registration_tab.simple_form.time_based.header.end': 'End',
  'work_hours_registration_tab.simple_form.time_based.header.start': 'Start',
  'work_hours_registration_tab.simple_form.time_based.intro': 'Breaks are entered in minutes.',
  'work_hours_registration_tab.simple_form.time_based.start': 'Start',
  'work_hours_registration_tab.simple_form.unlock': 'New template',
  'work_hours_registration_tab.template': 'Template',
  'work_hours_registration_tab.week.detailed_toggle.detailed': 'Detailed',
  'work_hours_registration_tab.week.detailed_toggle.simple': 'Simple',
  'work_hours_registration_tab.week.new_registration': 'Register working time',
  'work_hours_registration_tab.week.next': 'Next week',
  'work_hours_registration_tab.week.previous': 'Previous week',
  'work_hours_registration_tab.week.summary': 'Weekly hours: {summary}',
  'work_hours_registration_tab.week.summary.count.many': '{number} hours',
  'work_hours_registration_tab.week.summary.count.one': '{number} hour',
  'work_hours_registration_tab.week.this_week': 'This week',
  'work_hours_registration_tab.week.title': 'Week {week_number} ({week_from} - {week_to})',
  'work_hours_registration_tab.week.title.template': 'Week template',
}

enum CompanySetting {
  MORE_BONUS_OPTIONS = 'MoreBonusOptions',
  MORE_INCOME_TYPES = 'MoreIncomeTypes',
  ALLOW_NO_CPR = 'AllowNoCPR',
  ALLOW_FREELANCER_CVR = 'AllowFreelancerCVR',
  ALLOW_VACATION_FUND = 'AllowVacationFund',
  ALLOW_DAY_LABORER = 'AllowDayLaborer',
  SH_FRITVALG_INCREASE_TYPES = 'SHDageFritvalgIncreaseTypes',
  ALLOW_LEAVE_TYPE_EMPLOYEE_SELECTABLE = 'AllowLeaveTypeEmployeeSelectable',
  DISABLE_APP_TIME_REGISTRATION = 'DisableAppTimeRegistration',
  DISABLE_APP_LEAVE_REGISTRATION = 'DisableAppLeaveRegistration',
  DEPARTMENT_SELECT_TIME_REGISTRATION = 'DepartmentSelectTimeRegistration',
  ASK_FOR_OTP_DRAFT_STATE = 'AskForOTPDraftState',
  REGISTER_TIME_REGISTRATION_START_END = 'RegisterTimeRegistrationStartEnd',
  ALLOW_NET_PENSION = 'AllowNetPension',
  ALLOW_OTP_UNITES_AND_RATE = 'AllowOTPUnitsAndRate',
  ENABLE_APP_HOURLY_LEAVE_REGISTRATION = 'EnableAppHourlyLeaveRegistration',
  ALLOW_NEGATION_PAYROLL = 'AllowNegationPayroll',
  ENABLE_APP_FEE_REGISTRATION = 'EnableAppFeeRegistration',
  ALLOW_NON_SALARIED_PAID_VACATION = 'AllowNonSalariedPaidVacation',
  USE_STANDARD_RATES_FOR_UNKNOWN_ATP_HOURS = 'UseStandardRatesForUnknownATPHours',
  PAY_SLIP_TRANSPORT_DEFAULT_EBOKS = 'PaySlipTransportDefaultEBoks',
  PAY_SLIP_TRANSPORT_DEFAULT_MITDK = 'PaySlipTransportDefaultMitDK',
  PAY_SLIP_TRANSPORT_DEFAULT_EMAIL = 'PaySlipTransportDefaultEMail',
  PAY_SLIP_TRANSPORT_DEFAULT_SMS = 'PaySlipTransportDefaultSMS',
  DISABLE_APP_UPCOMING_PAY_SLIP = 'DisableAppUpcomingPaySlip',
  DISABLE_APP_FLEX_OVERTIME_REGISTRATION = 'DisableAppFlexOvertimeRegistration',
  DISABLE_APP_DETAILED_VACATION_PREDICTION = 'DisableAppDetailedVacationPrediction',
  ALLOW_APP_EMPLOYEE_UPDATE_BANKING = 'AllowEmployeeUpdateBanking',
  DISABLE_APP_PROJECTS_REGISTRATION = 'DisableAppProjectsRegistration',
  ALLOW_EMPLOYEE_TO_UPLOAD_DOCUMENTS = 'AllowEmployeeToUploadDocuments',
  ENABLE_WORK_HOURS_FEATURE = 'EnableWorkHoursFeature',
  ALLOW_RATE_PER_CAR_ALLOWANCE = 'AllowRatePerCarAllowance',
  REGISTER_WORK_HOURS_START_END = 'RegisterWorkHoursStartEnd',
  ENABLE_WORK_HOURS_AUTOMATIC = 'EnableWorkHoursAutomatic',
  DISABLE_APP_SALARY_REGISTRATION = 'DisableAppSalaryRegistration',
}
export default CompanySetting
